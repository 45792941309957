<template>
    <div
        data-spec-class="base-tab"
        :data-spec-value="isActive ? 'active' : 'inactive'"
        class="flex-1 sm:flex-none"
    >
        <a
            href="#"
            :class="classObject"
            @click.prevent="$emit('activate', id)"
        >
            <span> {{ label }} </span>
            <BaseBadge
                v-if="badge"
                class="ml-3"
                :size="BadgeSize.Large"
                >{{ badge }}</BaseBadge
            >
        </a>
    </div>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, defineEmits, computed } from 'vue';
import { TabsItemTheme, BadgeSize } from '../types';
import BaseBadge from './BaseBadge.vue';

defineEmits(['activate']);

interface Props {
    id: string;
    label: string;
    isActive?: boolean;
    theme?: TabsItemTheme;
    badge?: string | number;
}

const props = withDefaults(defineProps<Props>(), {
    isActive: false,
    theme: TabsItemTheme.Underline,
    badge: undefined,
});

const configuratorClassObject = computed(() => ({
    'inline-block px-0 sm:px-9 py-4 h-full w-full rounded-t border-t border-r border-l': true,
    'text-sm sm:text-lg text-center font-medium': true,
    'bg-gray-700 border-gray-700 text-blue-100': props.isActive,
    'bg-white border-gray-300 text-gray-700 hover:text-gray-400': !props.isActive,
}));

const underlineClassObject = computed(() => ({
    'inline-block px-0 sm:px-6 py-3 h-full w-full rounded-t': true,
    'text-sm sm:text-lg text-center font-medium': true,
    'border-t border-r border-l border-gray-200': true,
    'bg-white text-gray-700 cursor-default': props.isActive,
    'bg-blue-200 text-gray-300 hover:text-gray-400 border-b border-gray-200': !props.isActive,
}));

const classObject = computed(() => {
    switch (props.theme) {
        case TabsItemTheme.Configurator:
            return configuratorClassObject.value;
        case TabsItemTheme.Underline:
            return underlineClassObject.value;
        default:
            return underlineClassObject.value;
    }
});
</script>
