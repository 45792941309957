<template>
    <BaseForm
        data-spec-class="confirm_password-form"
        @submit.prevent="onSubmit"
    >
        <BaseAlertErrorServer
            v-if="hasServerErrors"
            :size="Size.Small"
            class="mb-4"
        />

        <BaseFieldInput
            v-model="fields.password"
            :type="TextFieldType.Password"
            :vuelidate-field="v$.password"
            :error-messages="userStore.confirmPasswordValidationError?.password"
            name="password"
            ref="passwordInputRef"
            data-spec-class="confirm_password-form__password"
        />

        <template #submit>
            <BaseButton
                :theme="Theme.Primary"
                :is-disabled="v$.$error || !fields.password.length"
                :is-loading="userStore.isConfirmingPassword"
                @click="onSubmit"
                class="-my-5 w-full self-end sm:w-fit"
                data-spec-class="confirm_password-form__submit"
            >
                {{ t('resources.user.actions.confirmPassword') }}
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import { reactive, computed, ref, onMounted, defineEmits, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { useUserStore } from '../modules/your/stores/userStore';
import { useToast } from '../modules/your/composables/toast';
import { Size, TextFieldType, Theme } from '../types';
import { useCore } from '../modules/your/composables/core';

const { t } = useI18n();
const userStore = useUserStore();
const { addSuccessToast } = useToast();

const emits = defineEmits(['confirm']);

const hasServerErrors = ref(false);

const passwordInputRef = ref(null);
const fields = reactive({
    password: '',
});

const rules = computed(() => ({
    password: {},
}));

const { coreState } = useCore();

const v$ = useVuelidate(rules, fields);

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        await userStore.confirmPassword(fields.password);
        if (userStore.confirmPasswordValidationError) {
            await nextTick();
            passwordInputRef.value.focus();
            passwordInputRef.value.select();
            return;
        }
        coreState.isPasswordConfirmed = true;
        emits('confirm');
        addSuccessToast(t('dialogs.confirmPassword.toast'));
    } catch (error) {
        if (error.response.status === 500) {
            hasServerErrors.value = true;
        }
    }
};

onMounted(() => {
    passwordInputRef.value.focus();
    passwordInputRef.value.select();
    userStore.confirmPasswordValidationError = null;
});
</script>
