import { SortDirection } from '../types';

export function isEmpty(object: object) {
    return Object.keys(object).length === 0;
}

export function isPresent(object: object) {
    return !isEmpty(object);
}

export const firstValue: any = (object: object) => {
    if (isEmpty(object)) {
        return null;
    }
    return Object.values(object)[0];
};

export const arrayToIndexObject: any = (array: any[], id = 'id') => {
    const object = {};
    array.forEach((arrayItem) => {
        object[arrayItem[id]] = arrayItem;
    });
    return object;
};

export const isEvent = (object) => {
    return object && typeof object === 'object' && 'type' in object && 'target' in object;
};

export function sortArrayByField(array, field, sortDirection = 'asc' as SortDirection) {
    return array.sort((a, b) => {
        let aValue = a[field];
        let bValue = b[field];

        if (
            aValue instanceof Date ||
            bValue instanceof Date ||
            Date.parse(aValue) ||
            Date.parse(bValue)
        ) {
            aValue = new Date(aValue);
            bValue = new Date(bValue);
        }

        if (sortDirection === SortDirection.Asc) {
            if (aValue < bValue) return -1;
            if (aValue > bValue) return 1;
            return 0;
        } else if (sortDirection === SortDirection.Desc) {
            if (aValue > bValue) return -1;
            if (aValue < bValue) return 1;
            return 0;
        }
    });
}

export const sortArrayBySorting = (array: object[], sorting: string) => {
    const sortingSplit = sorting.split(':');
    return sortArrayByField(array, sortingSplit[0], sortingSplit[1]);
};

export const deepEqualObject = (object1, obj2) => {
    if (object1 === obj2) return true;
    if (typeof object1 !== 'object' || typeof obj2 !== 'object' || object1 == null || obj2 == null)
        return false;

    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
        if (!keys2.includes(key) || !deepEqualObject(object1[key], obj2[key])) return false;
    }

    return true;
};

export const deepEqualObjects = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    return (
        arr1.every((object1) => arr2.some((obj2) => deepEqualObject(object1, obj2))) &&
        arr2.every((object1) => arr1.some((obj2) => deepEqualObject(object1, obj2)))
    );
};
