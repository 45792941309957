<template>
    <BaseTooltipHelp
        :theme="theme"
        data-spec-id="tooltip-help-business-hours"
    >
        {{ tooltipText }}
    </BaseTooltipHelp>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Theme } from '../../../types';
import BaseTooltipHelp from '../../../components/BaseTooltipHelp.vue';

const { t } = useI18n();

interface Props {
    theme?: Theme;
}

withDefaults(defineProps<Props>(), {
    theme: Theme.Dark,
});

const tooltipText = computed(() => {
    const germanTime = new Date();
    germanTime.toLocaleString('de-DE', { timeZone: 'Europe/Berlin' });

    const isSummerTime = germanTime.getTimezoneOffset() === -120;
    const timeZone = isSummerTime ? t('general.timezones.cest') : t('general.timezones.cet');
    const utcDifference = isSummerTime ? 'UTC+2' : 'UTC+1';

    return t('pages.DashboardPage.serviceTeaser.info', {
        timeZone,
        utcDifference,
    });
});
</script>
