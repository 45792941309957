export default {
    baseComponents: {
        basePagination: {
            previous: 'Previous',
            next: 'Next',
            metaInfo: `
      Showing <span class="font-medium">%{itemsFrom}</span>
      to <span class="font-medium">%{itemsTo}</span>
      of <span class="font-medium">%{itemsTotal}</span>
      results`,
        },
    },
    general: {
        period: 'Period',
        from: 'From',
        to: 'To',
        day: 'Day | Days',
        today: 'Today',
        week: '{count} week | {count} weeks',
        month: '{count} month | {count} months',
        noData: 'No data available yet',
        isSelfManaged: 'Self-managed',
        general: 'General',
        services: 'Services',
        base: 'Base',
        kilowattHour: 'Kilowatt hour | Kilowatt hours',
        all: 'All',
        watts: 'W',
        noResultsFound: 'No results found',
        noResultsFoundDescription: 'Clear your filters and try again.',
        inclusive: 'inclusive',
        cpu: 'CPU | CPUs',
        diffInSeconds: '%{count} second ago | %{count} seconds ago',
        diffInMinutes: '%{count} minute ago | %{count} minutes ago',
        diffInHours: '%{count} hour ago | %{count} hours ago',
        diffInDays: '%{count} day ago | %{count} days ago',
        diffInWeeks: '%{count} week ago | %{count} weeks ago',
        diffInMonths: '%{count} month ago | %{count} months ago',
        timezones: {
            cet: 'CET',
            cest: 'CEST',
        },
    },
    actions: {
        addEntry: 'Add entry',
        addFile: 'Add file',
        add: 'Add',
        create: 'Create',
        show: 'Show',
        showAll: 'Show all',
        destroy: 'Delete',
        edit: 'Edit',
        download: 'Download',
        confirm: 'Confirm',
        cancel: 'Cancel',
        save: 'Save',
        back: 'Back',
        next: 'Next',
        complete: 'Complete',
        adjust: 'Adjust',
        logout: 'Sign out',
        backToLogin: 'Back to login',
        createResource: 'Create {resource}',
        editResource: 'Edit {resource}',
        feedback: 'Feedback',
        deactivate: 'Deactivate',
        activate: 'Activate',
        moreInfo: 'More info',
        clearFilters: 'Clear filters',
        submitARequest: 'Submit a request',
        submit: 'Submit',
    },
    dialogs: {
        maintenance: {
            title: 'Maintenance work is currently taking place',
            message:
                'Your action could not be performed. Please excuse the inconvenience. The page will be reloaded automatically when the maintenance is completed.',
            countdown: 'Next try in {counter} seconds',
        },
        sessionExpiration: {
            title: 'Your session has expired',
            message: 'Please log in again.',
        },
        confirmation: {
            title: 'Are you sure?',
            message: 'Do you really want to continue?',
            destroyEntryMessage: 'Do you really want to delete this entry?',
        },
        feedback: {
            title: 'How can we improve our service?',
        },
        confirmPassword: {
            title: 'Confirm your password',
            toast: 'Password successfully confirmed',
        },
    },
    errors: {
        serverError: {
            message: 'Something went wrong!',
            description: 'Please try again later or contact our support.',
        },
        unconfirmedPassword: {
            message: 'Password was not confirmed!',
        },
    },
    messages: {
        configuratorMaintenance: {
            message: 'Maintenance work',
            description:
                'Maintenance work is currently being carried out on the configurator. It will be available again shortly.',
        },
    },
    validations: {
        required: 'This is a mandatory field',
        requiredIf: 'This is a mandatory field',
        minLength: 'At least {min} characters',
        email: 'The entered mail address is invalid',
        ipV4: 'The entered IPv4 address is invalid',
        between: 'Please enter a value between {min} and {max}',
        sameAs: 'Must match "{otherName}"',
        fileSize50: 'The file must not be larger than 50 MB',
        mustBeConfirmed: 'This field must be confirmed',
    },
    links: {
        privacy: 'Privacy',
        imprint: 'Imprint',
        dedicatedServer: 'Dedicated  Server',
        colocation: 'Colocation',
        cloudServer: 'Cloud Server',
        s3Storage: 'S3-Storage',
    },
};
