<template>
    <div
        class="border-b border-gray-200"
        data-spec-class="base-tabs"
    >
        <nav
            class="-mb-px flex space-x-1"
            data-spec-id="tabs"
        >
            <BaseTabsItem
                v-for="tab in tabItems"
                :id="tab.id"
                :key="tab.id"
                :label="tab.label"
                :badge="tab.badge"
                :is-active="tab.id === activeTabId"
                :data-spec-id="`tab-${tab.id}`"
                @activate="onActivate"
            />
            <input
                name="tab"
                type="hidden"
                :value="activeTabId"
                aria-hidden="true"
            />
        </nav>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, defineEmits, defineProps, computed, withDefaults, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { getUrlParam } from '../utils/windowUtils';
import { TabsItem } from '../types';

const router = useRouter();

interface Props {
    tabItems: TabsItem[];
    urlParameter?: string;
}

const props = withDefaults(defineProps<Props>(), {
    urlParameter: 'tab',
});

const emits = defineEmits(['change']);

const tabIds = computed(() => props.tabItems.map((tabItem: TabsItem) => tabItem.id));

const activeTabId = ref(tabIds.value[0]);

onMounted(() => {
    const urlTabParam = getUrlParam(props.urlParameter);
    if (urlTabParam && tabIds.value.includes(urlTabParam)) {
        activeTabId.value = urlTabParam;
    }

    emits('change', activeTabId.value);
});

watch(activeTabId, async (newActiveTabId) => {
    emits('change', newActiveTabId);
});

const onActivate = (tabId) => {
    activeTabId.value = tabId;
    router.replace({ query: { tab: tabId } });
};
</script>
