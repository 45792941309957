<template>
    <BaseCard
        class="animate-pulse !p-0"
        data-spec-class="card-skeleton"
    >
        <div class="p-6">
            <div class="mb-3 h-4 w-5/12 rounded bg-gray-300" />
            <div class="h-4 w-3/12 rounded bg-gray-200" />
        </div>

        <div class="px-6 pb-6">
            <div class="mb-3 h-4 w-10/12 rounded bg-gray-200" />
            <div class="mb-3 h-4 w-8/12 rounded bg-gray-200" />
            <div class="mb-3 h-4 w-9/12 rounded bg-gray-200" />
            <div class="mb-3 h-4 w-11/12 rounded bg-gray-200" />
            <div class="mb-3 h-4 w-9/12 rounded bg-gray-200" />
        </div>
    </BaseCard>
</template>
