<template>
    <input
        type="hidden"
        name="_token"
        :value="token"
    />
</template>

<script setup lang="ts">
const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content') || '';
</script>
