<template>
    <BaseAvatar
        :class="classObject"
        data-spec-class="pagination-link"
        :data-spec-value="type"
    >
        <slot />
    </BaseAvatar>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, computed } from 'vue';
import { PaginationLinkType, Theme } from '../types';
import BaseAvatar from './BaseAvatar.vue';

interface Props {
    type?: PaginationLinkType;
    theme?: Theme;
}

const props = withDefaults(defineProps<Props>(), {
    type: PaginationLinkType.Normal,
    theme: Theme.Light,
});

const classObject = computed(() => ({
    'px-2 font-mono': true,

    // normal
    'bg-transparent !text-blue-700 cursor-pointer': props.type === PaginationLinkType.Normal,
    'hover:bg-blue-300': props.type === PaginationLinkType.Normal && props.theme === Theme.Light,
    'border border-gray-500 bg-gray-600 hover:bg-gray-600 !text-white':
        props.type === PaginationLinkType.Normal && props.theme === Theme.Dark,

    // current
    '!bg-gray-400 !text-white':
        props.type === PaginationLinkType.Current && props.theme === Theme.Light,
    'border border-blue-600 !bg-blue-700 !text-gray-700':
        props.type === PaginationLinkType.Current && props.theme === Theme.Dark,

    // disabled
    'bg-transparent': props.type === PaginationLinkType.Disabled,
    '!text-gray-300': props.type === PaginationLinkType.Disabled && props.theme === Theme.Light,
    '!text-gray-500 border border-gray-600':
        props.type === PaginationLinkType.Disabled && props.theme === Theme.Dark,
}));
</script>
