<template>
    <BaseDialog
        :is-open="coreState.isMaintenanceModeActive"
        :is-closable="false"
        :size="Size.Small"
        data-spec-class="the-maintenance-dialog"
    >
        <template #icon>
            <img src="/img/icons/23m/503.svg" />
        </template>

        <BaseDialogTitle
            class="text-center"
            data-spec-class="the-maintenance-dialog__title"
        >
            {{ t('dialogs.maintenance.title') }}
        </BaseDialogTitle>
        <BaseDialogBody class="text-center">
            {{ t('dialogs.maintenance.message') }}
        </BaseDialogBody>
        <BaseDialogBody class="text-center">
            {{ t('dialogs.maintenance.countdown', { counter: counter }) }}
        </BaseDialogBody>
    </BaseDialog>
</template>

<script setup lang="ts">
import { watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCore } from '../modules/your/composables/core';
import { Size } from '../types';
import { useUserStore } from '../modules/your/stores/userStore';

const { coreState } = useCore();
const userStore = useUserStore();
const counterInterval = import.meta.env.VITE_VUE_MAINTENANCE_INTERVAL || 30;
const counter = ref(counterInterval);

const onMaintenance = () => {
    setInterval(async () => {
        if (counter.value === 0) {
            try {
                await userStore.fetchCurrentUser();
                coreState.isMaintenanceModeActive = false;
                location.reload();
            } catch (error) {
                counter.value = counterInterval;
            }
        } else {
            counter.value -= 1;
        }
    }, 1000);
};

watch(
    () => coreState.isMaintenanceModeActive,
    (newValue) => {
        if (newValue === true) {
            onMaintenance();
        }
    }
);

const { t } = useI18n();
</script>
