<template>
    <BaseDialog
        :is-open="coreState.isSessionExpired"
        :is-closable="false"
        :size="Size.Small"
        data-spec-class="the-session-expiration-dialog"
    >
        <BaseDialogTitle
            class="text-center"
            data-spec-class="the-session-expiration-dialog__title"
        >
            {{ t('dialogs.sessionExpiration.title') }}
        </BaseDialogTitle>
        <BaseDialogBody class="text-center">
            {{ t('dialogs.sessionExpiration.message') }}
        </BaseDialogBody>
        <BaseDialogBody class="mt-4 text-center">
            <BaseButton href="/login">
                {{ t('actions.backToLogin') }}
            </BaseButton>
        </BaseDialogBody>
    </BaseDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useCore } from '../modules/your/composables/core';
import { Size } from '../types';

const { coreState } = useCore();

const { t } = useI18n();
</script>
