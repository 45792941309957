/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import axios from 'axios';
import { getLocaleFromUrl } from './utils/windowUtils';
import { errors, useCore } from './modules/your/composables/core';

const { coreState, confirmPassword } = useCore();

// Full config:  https://github.com/axios/axios#request-config
const config = {
    headers: { 'Accept-Language': getLocaleFromUrl() },
    // baseURL: import.meta.env.VITE_VUE_API_URL || '',
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
};

const localAxios = axios.create(config);

localAxios.interceptors.request.use(
    (config) =>
        // Do something before request is sent
        config,
    (error) =>
        // Do something with request error
        Promise.reject(error)
);

// Add a response interceptor
localAxios.interceptors.response.use(
    (response) =>
        // Do something with response data
        response, // TODO: response.data leads to typescript issues
    async (error) => {
        if (error.response.status === 500 && error.response.data.message === 'Unauthenticated.') {
            coreState.isSessionExpired = true;
            return Promise.reject(error);
        }

        if (error.response.status === 503) {
            coreState.isMaintenanceModeActive = true;
            return Promise.reject(error);
        }

        if (error.response.status === 423) {
            try {
                const isPasswordConfirmed = await confirmPassword();
                coreState.isPasswordConfirmed = null;

                if (isPasswordConfirmed) {
                    coreState.isPasswordConfirmationRequired = false;
                    const response = await localAxios({
                        method: error.config.method,
                        url: error.config.url,
                    });
                    return Promise.resolve(response);
                }

                return Promise.reject(Error(errors.unconfirmedPassword));
            } catch (error) {
                coreState.isPasswordConfirmationRequired = false;
                return Promise.reject(error);
            }
        }

        coreState.isMaintenanceModeActive = false;
        coreState.isSessionExpired = false;
        coreState.isPasswordConfirmationRequired = false;

        return Promise.reject(error);
    }
);

export default localAxios;
