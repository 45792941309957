<template>
    <Popover class="relative">
        <PopoverButton
            class="focus:outline-none"
            data-spec-class="contact-button__trigger"
        >
            <div
                class="-mb-1.5 whitespace-nowrap rounded-t-md bg-gray-200 px-4 py-2 text-gray-700 transition duration-300 ease-out hover:-translate-y-1.5 ui-open:transform ui-open:opacity-0"
            >
                Support
            </div>
        </PopoverButton>

        <PopoverPanel
            v-slot="{ close }"
            class="absolute bottom-6 z-20 w-80 -translate-x-1/2 rotate-90"
            data-spec-class="contact-button__panel"
        >
            <div class="rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="absolute right-0 top-0 mr-3 hidden pt-3 sm:block">
                    <button
                        @click="onClose(close)"
                        type="button"
                        class="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        data-spec-class="contact-button__close"
                    >
                        <span class="sr-only">Close</span>
                        <XMarkIcon
                            class="h-6 w-6"
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div class="mb-6">
                    <h2 class="text-lg text-blue-700">
                        {{ t('pages.DashboardPage.contactTeaser.title') }}
                    </h2>
                    <div>
                        <div class="flex justify-between">
                            <span>
                                {{ t('pages.DashboardPage.contactTeaser.phone') }}
                            </span>
                            <BaseLink :href="`tel:${contact.phone.general.densed}`">
                                {{ contact.phone.general.pretty }}
                            </BaseLink>
                        </div>

                        <div class="flex justify-between">
                            <span>
                                {{ t('pages.DashboardPage.contactTeaser.mail') }}
                            </span>
                            <BaseLink :href="hrefSupport">
                                {{ contact.mail.support }}
                            </BaseLink>
                        </div>

                        <div class="flex justify-between">
                            <span>
                                {{ t('pages.DashboardPage.contactTeaser.emergency') }}
                            </span>
                            <BaseLink :href="hrefNoc">
                                {{ contact.mail.noc }}
                            </BaseLink>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex items-center">
                        <h2 class="text-lg text-blue-700">
                            {{ t('pages.DashboardPage.serviceTeaser.title') }}
                        </h2>
                        <BusinessHoursTooltip />
                    </div>
                    <div class="text-base">
                        <div class="flex justify-between">
                            <span class="mb-1">
                                {{ t('pages.DashboardPage.serviceTeaser.mondayToThursday') }}
                            </span>
                            <span>
                                {{
                                    t(
                                        'pages.DashboardPage.serviceTeaser.mondayToThursdayOpeningHours'
                                    )
                                }}
                            </span>
                        </div>
                        <div class="flex justify-between">
                            <span>
                                {{ t('pages.DashboardPage.serviceTeaser.friday') }}
                            </span>
                            <span>
                                {{ t('pages.DashboardPage.serviceTeaser.fridayOpeningHours') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </PopoverPanel>
    </Popover>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import { contact } from '../config/data';
import BusinessHoursTooltip from '../modules/your/components/BusinessHoursTooltip.vue';

const { t } = useI18n();

const isOpen = ref(false);

const onClose = (close) => {
    isOpen.value = false;
    close();
};

const hrefSupport = ref(`mailto:${contact.mail.support}`);
const hrefNoc = ref(`mailto:${contact.mail.noc}`);
</script>
