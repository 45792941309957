<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 558.6 558.6"
        xml:space="preserve"
    >
        <path
            d="M558.6,518.5L357.3,317.2c24.8-33.1,39.6-74.3,39.6-118.8C396.9,89,307.8,0,198.4,0S0,89,0,198.4
	s89,198.4,198.4,198.4c44.5,0,85.6-14.7,118.8-39.6l201.3,201.3L558.6,518.5z M56.7,198.4c0-78.2,63.6-141.7,141.7-141.7
	s141.7,63.6,141.7,141.7c0,78.2-63.6,141.7-141.7,141.7S56.7,276.6,56.7,198.4z"
        />
    </svg>
</template>
