<template>
    <div v-if="!isEditing">
        {{ modelValue || NO_DATA_SYMBOL }}
    </div>
    <BaseFieldInput
        v-if="isEditing"
        :modelValue="modelValue"
        :vuelidate-field="vuelidateField"
        :error-messages="errorMessages"
        :is-clearable="true"
        :name="name"
        :data-spec-class="dataSpecClass"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup.enter="emits('update')"
        @keyup.esc="emits('cancel')"
        @clear="emits('clear')"
        ref="inputRef"
        class="py-0.5"
    />
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, ref } from 'vue';
import { NO_DATA_SYMBOL } from '../modules/your/composables/core';

const emits = defineEmits(['update:modelValue', 'update', 'cancel', 'clear']);
defineExpose({ focus, select });

interface Props {
    isEditing: boolean;
    modelValue: string;
    name: string;
    vuelidateField: any;
    errorMessages?: string[];
    dataSpecClass?: string;
}

withDefaults(defineProps<Props>(), {
    errorMessages: undefined,
    dataSpecClass: 'field-edit',
});

const inputRef = ref(null);

function focus() {
    inputRef.value.focus();
}

function select() {
    inputRef.value.select();
}
</script>
