<template>
    <td
        :class="classObject"
        data-spec-class="table-cell-data"
    >
        <slot />
    </td>
</template>

<script setup lang="ts">
import { withDefaults, defineProps } from 'vue';
import { TableCellType } from '../types';

interface Props {
    type?: TableCellType;
}

const props = withDefaults(defineProps<Props>(), {
    type: TableCellType.Normal,
});

const classObject = {
    'whitespace-nowrap py-1.5': true,
    'text-gray-700 px-6': props.type === TableCellType.Normal,
    'pl-3 pr-3 sm:pr-6 flex justify-end': props.type === TableCellType.Actions,
};
</script>
