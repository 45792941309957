<template>
    <div data-spec-class="table-wired">
        <div class="flex justify-end">
            <div>
                <div
                    v-if="searchableAttributeLabels && isSearchable"
                    class="flex items-end space-x-6 px-6 pb-3 pt-6"
                >
                    <BaseFieldInput
                        :model-value="search"
                        :is-clearable="true"
                        :placeholder="searchableAttributeLabels"
                        :error-messages="validationError?.search"
                        @update:modelValue="(value) => emits('update:search', value)"
                        @clear="onSearchClear"
                        @keyup.enter="onUpdate"
                        name="search"
                        class="py-0.5 focus:w-80"
                        data-spec-class="table-wired__search"
                        autocomplete="new"
                    >
                        <template #addOn>
                            <div class="bg-white pl-1">
                                <IconMagnifyingGlass
                                    @click="onUpdate"
                                    class="h-4 w-4 cursor-pointer fill-blue-700"
                                    data-spec-class="table-wired__search_submit"
                                />
                            </div>
                        </template>
                    </BaseFieldInput>
                    <slot
                        v-if="isFilterable"
                        name="customFilters"
                    />
                </div>
            </div>
        </div>
        <BaseTable>
            <template #wrapper>
                <div class="relative">
                    <BaseOverlay
                        :is-open="isLoading"
                        class="md:rounded-lg"
                    >
                        <div class="flex h-full items-center justify-center">
                            <BaseSpinner class="h-24 w-24" />
                        </div>
                    </BaseOverlay>

                    <div class="overflow-hidden md:rounded-lg">
                        <table class="min-w-full">
                            <BaseTableHead>
                                <BaseTableRow>
                                    <BaseTableCellHeader v-if="hasNotifications">
                                        &nbsp;
                                    </BaseTableCellHeader>
                                    <BaseTableCellHeader
                                        v-for="(attribute, i) in attributes"
                                        :is-sortable="attribute.isSortable"
                                        :sorting="sorting"
                                        :attributeName="attribute.name"
                                        :key="attribute.position"
                                        :class="attribute.columnClass"
                                        @update:page-sorting="onUpdatePageSorting"
                                    >
                                        {{ attribute.label }}
                                    </BaseTableCellHeader>
                                </BaseTableRow>
                            </BaseTableHead>
                            <tbody v-if="items.length">
                                <template
                                    v-for="(item, index) in items"
                                    :key="index"
                                >
                                    <slot
                                        name="item"
                                        v-bind="item"
                                    />
                                </template>
                            </tbody>
                            <tbody v-else>
                                <tr class="h-24">
                                    <td
                                        :colspan="attributes.length + 1"
                                        class="text-center"
                                        data-spec-class="table__empty"
                                    >
                                        {{ t('general.noData') }}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot v-if="meta && meta.total > meta.per_page">
                                <tr class="border-t">
                                    <td
                                        :colspan="attributes.length + 1"
                                        class="px-6 py-3"
                                    >
                                        <BasePagination
                                            :meta="meta"
                                            @update:page="(page) => onUpdate(page)"
                                        />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </template>
        </BaseTable>
    </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineEmits, defineProps, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Attribute, IndexValidationError, PaginationMeta, TableItem } from '../types';
import BasePagination from './BasePagination.vue';
import BaseOverlay from './BaseOverlay.vue';
import BaseSpinner from './BaseSpinner.vue';
import BaseFieldInput from './BaseFieldInput.vue';
import IconMagnifyingGlass from '../modules/your/components/icons/IconMagnifyingGlass.vue';
import { isEvent } from '../utils/objectUtils';

const { t } = useI18n();

interface Props {
    attributes: Attribute[];
    search?: string;
    sorting?: string;
    items: TableItem[];
    isLoading?: boolean;
    meta?: PaginationMeta;
    initialSorting?: string;
    validationError?: IndexValidationError | null;
    isSearchable?: boolean;
    isFilterable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    isLoading: false,
    meta: undefined,
    validationError: null,
    isSearchable: false,
    isFilterable: false,
});

const emits = defineEmits(['update:page', 'update:search', 'update:sorting']);

const hasNotifications = computed(() => props.items.some((item) => item.notifications?.length));

const searchableAttributeLabels = computed(() => {
    const labels = props.attributes
        .map((attribute) => {
            if (!attribute.isSearchable) {
                return null;
            }
            return attribute.label;
        })
        .filter((value) => value !== null)
        .join(', ');
    return labels;
});

function onUpdatePageSorting({ attributeName, sortDirection }) {
    if (props.meta) {
        emits('update:sorting', '');
        if (sortDirection) {
            emits('update:sorting', `${attributeName}:${sortDirection}`);
        }
        emits('update:page', {
            page: props.meta.current_page,
            sortings: [props.sorting],
            search: props.search,
        });
    }
}

function onUpdate(page = null as number | null) {
    if (isEvent(page)) page = null;

    if (props.meta) {
        emits('update:page', {
            page: page ?? props.meta.current_page,
            sortings: [props.sorting],
            search: props.search,
        });
    }
}

const onSearchClear = async () => {
    await emits('update:search', '');
    onUpdate();
};
</script>
