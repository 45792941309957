<template>
    <dt
        :class="classObject"
        data-spec-class="description-list-title"
    >
        <slot />
    </dt>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';
import { Theme } from '../types';

interface Props {
    theme?: Theme;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Normal,
});

const classObject = computed(() => ({
    'text-gray-400 text-base': props.theme === Theme.Normal,
    'text-blue-700 text-xl font-bold': props.theme === Theme.Primary,
}));
</script>
