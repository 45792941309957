<template>
    <BaseCard
        class="animate-pulse bg-gray-200 !p-0"
        data-spec-class="card-skeleton"
    >
        <div class="bg-gray-100 p-6">
            <div class="mb-3 h-4 w-3/12 rounded bg-gray-200" />
            <div class="h-4 w-5/12 rounded bg-gray-300" />
        </div>

        <div class="p-6">
            <div class="mb-2 flex justify-between">
                <div class="mb-3 h-4 w-3/12 rounded bg-gray-200" />
                <div class="h-4 w-2/12 rounded bg-gray-100" />
            </div>
            <div class="mb-2 flex justify-between">
                <div class="mb-3 h-4 w-2/12 rounded bg-gray-200" />
                <div class="h-4 w-3/12 rounded bg-gray-100" />
            </div>
            <div class="flex justify-between">
                <div class="mb-3 h-4 w-4/12 rounded bg-gray-200" />
                <div class="h-4 w-2/12 rounded bg-gray-100" />
            </div>
        </div>
    </BaseCard>
</template>
