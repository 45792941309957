<template>
    <button
        type="button"
        :class="classObject"
        class="inline-flex w-full justify-center rounded-md border px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:w-auto sm:text-sm"
    >
        <slot />
    </button>
</template>

<script lang="ts" setup>
import { computed, withDefaults, defineProps } from 'vue';
import { Theme } from '../types';

interface Props {
    theme?: Theme;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Normal,
});

const classObject = computed(() => {
    return {
        'border-gray-300 bg-white text-gray-700 hover:text-gray-500 focus:ring-indigo-500':
            props.theme === Theme.Normal,
    };
});
</script>
