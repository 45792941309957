<template>
    <BaseCard
        class="animate-pulse bg-gray-200"
        data-spec-class="card-skeleton"
    >
        <template #avatar>
            <div class="h-10 w-10 rounded-full bg-gray-300" />
        </template>

        <BaseCardTitle>
            <div class="mb-3 h-4 w-1/3 rounded bg-gray-300" />
        </BaseCardTitle>
        <BaseCardContent>
            <div class="h-4 w-2/3 rounded bg-gray-100" />
        </BaseCardContent>
    </BaseCard>
</template>
