<template>
    <form
        class="flex flex-col"
        data-spec-class="user-feedback-form"
    >
        <BaseFieldTextarea
            v-model="fields.message"
            :placeholder="t('forms.sendFeedback.message.placeholder')"
            :vuelidate-field="v$.message"
            name="message"
            ref="messageTextareaRef"
            data-spec-class="user-feedback-form__message"
        />
        <BaseButton
            :theme="Theme.Primary"
            :is-disabled="v$.$error || fields.message.length === 0"
            :is-loading="userStore.isSendingFeedback"
            @click="onSubmit"
            class="mt-3 self-center"
            data-spec-class="user-feedback-form__submit"
        >
            {{ t('forms.sendFeedback.submit') }}
        </BaseButton>
    </form>
</template>

<script setup lang="ts">
import { reactive, computed, ref, onMounted, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { useUserStore } from '../stores/userStore';
import { useToast } from '../composables/toast';
import { Theme } from '../../../types';

const { t } = useI18n();
const userStore = useUserStore();
const { addSuccessToast, addErrorToast } = useToast();

const emits = defineEmits(['submit']);

const messageTextareaRef = ref(null);
const fields = reactive({
    message: '',
});

const rules = computed(() => ({
    message: {},
}));

const v$ = useVuelidate(rules, fields);

const onSubmit = async () => {
    const isFormCorrect = await v$.value.$validate();
    if (!isFormCorrect) return;

    try {
        await userStore.sendFeedback(fields.message);
        if (userStore.sendFeedbackValidationErrors) {
            return;
        }
        emits('submit');
        addSuccessToast(t('messages.success.saved'));
    } catch (error) {
        addErrorToast(t('errors.serverError.message'));
    }
};

onMounted(() => {
    messageTextareaRef.value.focus();
    messageTextareaRef.value.select();
});
</script>
