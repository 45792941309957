<template>
    <div data-spec-class="grid-wired">
        <div
            class="flex justify-end"
            v-if="searchableAttributeLabels"
        >
            <div class="flex justify-end pb-6">
                <BaseFieldInput
                    :model-value="search"
                    :is-clearable="true"
                    :placeholder="searchableAttributeLabels"
                    :error-messages="validationError?.search"
                    @update:modelValue="(value) => emits('update:search', value)"
                    @clear="onSearchClear"
                    @keyup.enter="onUpdate"
                    name="search"
                    class="py-0.5 focus:w-80"
                    data-spec-class="grid-wired__search"
                >
                    <template #addOn>
                        <div class="bg-white pl-1">
                            <IconMagnifyingGlass
                                @click="onUpdate"
                                class="h-4 w-4 cursor-pointer fill-blue-700"
                                data-spec-class="grid-wired__search_submit"
                            />
                        </div>
                    </template>
                </BaseFieldInput>
            </div>
        </div>

        <div class="relative">
            <BaseOverlay
                :is-open="isLoading"
                class="md:rounded-lg"
            >
                <div class="flex h-full items-center justify-center">
                    <BaseSpinner class="h-24 w-24" />
                </div>
            </BaseOverlay>
            <div>
                <slot v-if="!isEmpty" />

                <div v-else>
                    <BaseEmptyState :has-action="false">
                        <template #icon> <ExclamationCircleIcon /> </template>

                        {{ t('general.noData') }}
                    </BaseEmptyState>
                </div>

                <BasePagination
                    class="mt-6"
                    v-if="meta && meta.total > meta.per_page"
                    :meta="meta"
                    @update:page="(page) => onUpdate(page)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { withDefaults, defineEmits, defineProps, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Attribute, IndexValidationError, PaginationMeta, TableItem } from '../types';
import BasePagination from './BasePagination.vue';
import BaseOverlay from './BaseOverlay.vue';
import BaseSpinner from './BaseSpinner.vue';
import BaseFieldInput from './BaseFieldInput.vue';
import IconMagnifyingGlass from '../modules/your/components/icons/IconMagnifyingGlass.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { isEvent } from '../utils/objectUtils';

const { t } = useI18n();

const emits = defineEmits(['update:page', 'update:search']);

interface Props {
    attributes: Attribute[];
    isEmpty?: boolean;
    isLoading?: boolean;
    meta?: PaginationMeta;
    search?: string;
    validationError?: IndexValidationError | null;
}

const props = withDefaults(defineProps<Props>(), {
    isLoading: false,
    meta: undefined,
    isEmpty: false,
    search: '',
    validationError: null,
});

const searchableAttributeLabels = computed(() => {
    const labels = props.attributes
        .map((attribute) => {
            if (!attribute.isSearchable) {
                return null;
            }
            return attribute.label;
        })
        .filter((value) => value !== null)
        .join(', ');
    return labels;
});

function onUpdate(page = null as number | null) {
    if (isEvent(page)) page = null;

    if (props.meta) {
        emits('update:page', {
            page: page ?? props.meta.current_page,
            sortings: [],
        });
    }
}

const onSearchClear = async () => {
    await emits('update:search', '');
    onUpdate();
};
</script>
