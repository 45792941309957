<template>
    <Menu
        as="div"
        class="relative inline-block text-left"
        data-spec-class="dropdown_menu"
    >
        <div>
            <MenuButton data-spec-class="dropdown_menu__toggle">
                <slot />

                <span class="sr-only">Open options</span>
            </MenuButton>
        </div>
        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                :class="classObjectMenu"
                data-spec-class="dropdown_menu__menu"
            >
                <div class="py-1">
                    <slot name="menu-items" />
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, computed } from 'vue';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue';

const classObjectMenu = computed(() => ({
    'absolute right-0 z-20 mt-2 w-56 origin-top-right rounded shadow ring-1 ring-black ring-opacity-5 focus:outline-none':
        true,
    'bg-white text-gray-600': true,
}));
</script>
