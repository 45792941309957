<template>
    <BaseTooltip
        :theme="theme"
        @update="onUpdate"
        data-spec-class="tooltip-help"
    >
        <template #trigger>
            <QuestionMarkCircleIcon :class="classObject" />
        </template>
        <slot> </slot>
    </BaseTooltip>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline';
import { Size, Theme } from '../types';

interface Props {
    size?: Size;
    theme?: Theme;
}

const props = withDefaults(defineProps<Props>(), {
    size: Size.Normal,
    theme: Theme.Dark,
});

const isActive = ref(false);

const classObject = computed(() => ({
    'text-gray-400 hover:text-gray-300 transition hover:scale-110': props.theme === Theme.Dark,
    '!text-gray-300 scale-110': props.theme === Theme.Dark && isActive.value,
    'h-7 w-7': props.size === Size.Large,
    'h-5 w-5': props.size === Size.Normal,
}));

const onUpdate = (updatedIsActive: boolean) => {
    isActive.value = updatedIsActive;
};
</script>
