<template>
    <BaseField
        :label="label"
        :hint="hint"
        :is-optional="isOptional"
        :name="name"
        :error-messages="errorMessagesTotal"
        data-spec-class="field-select"
    >
        <select
            v-bind="$attrs"
            :name="name"
            :class="classObject"
            :disabled="isDisabled"
            :value="modelValue"
            @change="$emit('update:modelValue', $event.target.value)"
            :data-spec-class="dataSpecClass"
            ref="select"
        >
            <option
                v-for="option in options"
                :key="option.key"
                :value="option.key"
                :class="option.class"
            >
                {{ option.label }}
            </option>
        </select>
    </BaseField>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed, ref, defineExpose } from 'vue';
import { SelectOption, Theme } from '../types';

defineEmits(['update:modelValue']);

const select = ref(null);

function focus() {
    select.value.focus();
}

defineExpose({ focus });

interface Props {
    label?: string;
    hint?: string;
    isOptional?: boolean;
    isDisabled?: boolean;
    modelValue?: string;
    vuelidateField?: any;
    errorMessages?: string[];
    name: string;
    customThemeBase?: string;
    customThemeNormal?: string;
    dataSpecClass?: string;
    hasAutoDirty?: boolean;
    options: SelectOption[];
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    hint: '',
    isOptional: false,
    isDisabled: false,
    modelValue: undefined,
    vuelidateField: undefined,
    errorMessages: [],
    customThemeBase: undefined,
    customThemeNormal: undefined,
    dataSpecClass: 'field-select__select',
    hasAutoDirty: true,
    options: [],
});

const vuelidateErrors = computed(() => {
    if (props.vuelidateField?.$errors.length) {
        return props.vuelidateField.$errors.map((error) => error.$message);
    }

    return [];
});

const errorMessagesTotal = computed(() => [...vuelidateErrors.value, ...props.errorMessages]);

const theme = computed(() => {
    if (props.isDisabled) {
        return Theme.Disabled;
    }
    if (errorMessagesTotal.value.length) {
        return Theme.Danger;
    }

    return Theme.Normal;
});

const themeBase = computed(() => {
    const defaultThemeBase = 'block w-full rounded-md shadow-sm sm:text-sm';
    return typeof props.customThemeBase === 'string' ? props.customThemeBase : defaultThemeBase;
});

const themeNormal = computed(() => {
    let defaultThemeNormal = 'border-gray-300 focus:border-blue-500 focus:ring-blue-500';

    return typeof props.customThemeNormal === 'string'
        ? props.customThemeNormal
        : defaultThemeNormal;
});

const classObject = computed(() => ({
    [themeBase.value]: true,
    [themeNormal.value]: theme.value === Theme.Normal,
    'border-red-light pr-10 text-red-dark placeholder-red-light focus:border-red focus:outline-none focus:ring-red':
        theme.value === Theme.Danger,
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500':
        theme.value === Theme.Disabled,
}));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
