<template>
    <BaseField
        :label="label"
        :placeholder="placeholder"
        :hint="hint"
        :is-optional="isOptional"
        :name="name"
        :error-messages="errorMessagesTotal"
        data-spec-class="field-textarea"
    >
        <div :class="['relative rounded-md', wrapperClassAddition]">
            <textarea
                v-bind="$attrs"
                :name="name"
                :class="classObject"
                :placeholder="placeholder"
                :disabled="isDisabled"
                :value="modelValue"
                ref="textarea"
                @input="$emit('update:modelValue', $event.target.value)"
                @blur="hasAutoDirty ? vuelidateField?.$touch() : () => {}"
                :data-spec-class="dataSpecClass"
            />

            <div
                v-if="errorMessagesTotal.length || !!$slots.addOn"
                class="absolute inset-y-0 right-0 flex items-center pr-3"
            >
                <ExclamationCircleIcon
                    v-if="errorMessagesTotal.length"
                    class="h-5 w-5 text-red"
                    aria-hidden="true"
                />

                <span
                    v-if="!!$slots.addOn"
                    class="ml-1 text-gray-500 sm:text-sm"
                >
                    <slot name="addOn" />
                </span>
            </div>
        </div>
    </BaseField>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed, ref, defineExpose } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';
import { Theme } from '../types';

defineEmits(['update:modelValue']);

const textarea = ref(null);

function focus() {
    textarea.value.focus();
}

function select() {
    textarea.value.select();
}

defineExpose({ focus, select });

interface Props {
    name: string;
    label?: string;
    hint?: string;
    placeholder?: string;
    isOptional?: boolean;
    isDisabled?: boolean;
    modelValue?: string;
    vuelidateField?: any;
    errorMessages?: string[];
    wrapperClassAddition?: string;
    customThemeBase?: string;
    customThemeNormal?: string;
    dataSpecClass?: string;
    hasAutoDirty?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    hint: '',
    placeholder: '',
    isOptional: false,
    isDisabled: false,
    modelValue: undefined,
    vuelidateField: undefined,
    errorMessages: [],
    wrapperClassAddition: undefined,
    customThemeBase: undefined,
    customThemeNormal: undefined,
    dataSpecClass: 'field-textarea__textarea',
    hasAutoDirty: true,
});

const vuelidateErrors = computed(() => {
    if (props.vuelidateField?.$errors.length) {
        return props.vuelidateField.$errors.map((error) => error.$message);
    }

    return [];
});

const errorMessagesTotal = computed(() => [...vuelidateErrors.value, ...props.errorMessages]);

const theme = computed(() => {
    if (props.isDisabled) {
        return Theme.Disabled;
    }
    if (errorMessagesTotal.value.length) {
        return Theme.Danger;
    }

    return Theme.Normal;
});

const themeBase = computed(() => {
    const defaultThemeBase = 'block w-full rounded-md shadow-sm sm:text-sm';
    return typeof props.customThemeBase === 'string' ? props.customThemeBase : defaultThemeBase;
});

const themeNormal = computed(() => {
    const defaultThemeNormal =
        'border-gray-300 focus:border-blue-500 focus:ring-blue-500 placeholder-gray-300';
    return typeof props.customThemeNormal === 'string'
        ? props.customThemeNormal
        : defaultThemeNormal;
});

const classObject = computed(() => ({
    [themeBase.value]: true,
    [themeNormal.value]: theme.value === Theme.Normal,
    'border-red-light pr-10 text-red-dark placeholder-red-light focus:border-red focus:outline-none focus:ring-red':
        theme.value === Theme.Danger,
    'disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-500':
        theme.value === Theme.Disabled,
}));
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
