<template>
    <div
        class="flex items-center justify-between"
        data-spec-class="pagination"
    >
        <div class="flex flex-1 justify-between sm:hidden">
            <BasePaginationLink
                :theme="theme"
                :type="isFirstPage ? 'disabled' : 'normal'"
                class="!w-auto"
                @click="onPreviousPageClick"
                data-spec-class="pagination__previous-m"
            >
                {{ $t('baseComponents.basePagination.previous') }}
            </BasePaginationLink>

            <BasePaginationLink
                :theme="theme"
                :type="isLastPage ? 'disabled' : 'normal'"
                @click="onNextPageClick"
                class="!w-auto"
                data-spec-class="pagination__next-m"
            >
                {{ $t('baseComponents.basePagination.next') }}
            </BasePaginationLink>
        </div>

        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div v-if="hasMetaInfo">
                <p
                    class="text-sm"
                    :class="{
                        'text-gray-300': theme === Theme.Dark,
                        'text-gray-400': theme === Theme.Light,
                    }"
                    data-spec-class="pagination__meta-info"
                    v-html="
                        $t('baseComponents.basePagination.metaInfo', {
                            itemsFrom: meta.from,
                            itemsTo: meta.to,
                            itemsTotal: meta.total,
                        })
                    "
                />
            </div>
            <div>
                <nav
                    class="inline-flex space-x-2"
                    aria-label="Pagination"
                >
                    <BasePaginationLink
                        :theme="theme"
                        :type="isFirstPage ? 'disabled' : 'normal'"
                        @click="onPreviousPageClick"
                        data-spec-class="pagination__previous-d"
                    >
                        <span class="sr-only">
                            {{ $t('baseComponents.basePagination.previous') }}
                        </span>
                        <ChevronLeftIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </BasePaginationLink>

                    <template v-if="meta.last_page > 7">
                        <BasePaginationLink
                            :theme="theme"
                            :type="isFirstPage ? 'current' : 'normal'"
                            @click="isFirstPage ? () => {} : $emit('update:page', 1)"
                            data-spec-id="pagination__position-1"
                            data-spec-class="pagination__position"
                        >
                            {{ 1 }}
                        </BasePaginationLink>

                        <BasePaginationLink
                            v-for="linkObject in clippingLinkObjects"
                            :theme="theme"
                            :key="linkObject.position"
                            :type="linkObject.type"
                            @click="linkObject.click"
                            :data-spec-id="`pagination__position-${linkObject.position}`"
                            data-spec-class="pagination__position"
                        >
                            {{ linkObject.label }}
                        </BasePaginationLink>

                        <BasePaginationLink
                            :theme="theme"
                            :type="isLastPage ? 'current' : 'normal'"
                            @click="isLastPage ? () => {} : $emit('update:page', meta.last_page)"
                            data-spec-id="pagination__position-7"
                            data-spec-class="pagination__position"
                        >
                            {{ meta.last_page }}
                        </BasePaginationLink>
                    </template>

                    <template v-else>
                        <BasePaginationLink
                            v-for="page in meta.last_page"
                            :theme="theme"
                            :key="page"
                            :type="page === meta.current_page ? 'current' : 'normal'"
                            @click="
                                page === meta.current_page ? () => {} : $emit('update:page', page)
                            "
                            :data-spec-id="`pagination__position-${page}`"
                            data-spec-class="pagination__position"
                        >
                            {{ page }}
                        </BasePaginationLink>
                    </template>

                    <BasePaginationLink
                        :theme="theme"
                        :type="isLastPage ? 'disabled' : 'normal'"
                        @click="onNextPageClick"
                        data-spec-class="pagination__next-d"
                    >
                        <span class="sr-only">
                            {{ $t('baseComponents.basePagination.next') }}
                        </span>
                        <ChevronRightIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                        />
                    </BasePaginationLink>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';
import { PaginationMeta, Theme } from '../types';

interface Props {
    meta: PaginationMeta;
    theme?: Theme;
    hasMetaInfo: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Light,
    hasMetaInfo: true,
});

const emits = defineEmits(['update:page']);

const isFirstPage = computed(() => props.meta.current_page === 1);

const isLastPage = computed(() => props.meta.current_page === props.meta.last_page);

function onPreviousPageClick() {
    if (isFirstPage.value) {
        return () => ({});
    }
    return emits('update:page', props.meta.current_page - 1);
}

function onNextPageClick() {
    if (isLastPage.value) {
        return () => ({});
    }
    return emits('update:page', props.meta.current_page + 1);
}

function clippingLinkObjectByPosition(position) {
    const positionCount = 7;
    const clippingThreshold = 3;
    const positionOffset = position + clippingThreshold - positionCount;
    let isClippingRight = false;
    let isClippingLeft = false;
    let positionPage;
    let positionType;

    // is clipping right
    if (props.meta.current_page <= clippingThreshold) {
        isClippingRight = true;
        positionPage = position;
        if (position === 6) {
            positionType = 'disabled';
        }
    }

    // is clipping left
    if (props.meta.current_page >= props.meta.last_page - clippingThreshold) {
        isClippingLeft = true;
        positionPage = props.meta.last_page - (positionCount - position);
        if (position === 2) {
            positionType = 'disabled';
        }
    }

    // is clipping left and right
    if (!isClippingLeft && !isClippingRight) {
        positionPage = props.meta.current_page + positionOffset;
        if (position === 2 || position === 6) {
            positionType = 'disabled';
        }
    }

    positionType =
        positionType || (positionPage === props.meta.current_page ? 'current' : 'normal');
    return {
        position,
        label: positionType === 'disabled' ? '...' : positionPage,
        type: positionType,
        click: positionType === 'normal' ? () => emits('update:page', positionPage) : () => ({}),
    };
}

const clippingLinkObjects = computed(() =>
    [2, 3, 4, 5, 6].map((position) => clippingLinkObjectByPosition(position))
);
</script>
