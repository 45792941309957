import { createI18n } from 'vue-i18n';
import generalTranslationsDe from '../../../i18n/de';
import generalTranslationsEn from '../../../i18n/en';
import yourTranslationsDe from './de';
import yourTranslationsEn from './en';
import { getLocaleFromUrl } from '../../../utils/windowUtils';

const numberFormats: any = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            notation: 'standard',
            currencyDisplay: 'symbol',
        },
    },
    de: {
        currency: {
            style: 'currency',
            currency: 'EUR',
            useGrouping: true,
            currencyDisplay: 'symbol',
        },
    },
};

const datetimeFormats: any = {
    en: {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        pretty: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        },
    },
    de: {
        short: {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        },
        pretty: {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        },
    },
};

export default createI18n({
    numberFormats,
    datetimeFormats,
    legacy: false,
    locale: getLocaleFromUrl(),
    fallbackLocale: import.meta.env.VITE_VUE_I18N_FALLBACK_LOCALE || 'en',
    globalInjection: true,
    messages: {
        de: Object.assign(generalTranslationsDe, yourTranslationsDe, {}),
        en: Object.assign(generalTranslationsEn, yourTranslationsEn, {}),
    },
});
