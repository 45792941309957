<template>
    <slot
        name="trigger"
        :open="open"
    />
    <BaseDialog
        :is-open="isOpen"
        :size="Size.Small"
        @close="close"
        data-spec-class="dialog--confirm"
    >
        <BaseDialogTitle data-spec-class="dialog--confirm__title">
            <template v-if="titleText">
                {{ t(titleText) }}
            </template>
            <template v-else>
                {{ t('dialogs.confirmation.title') }}
            </template>
        </BaseDialogTitle>

        <BaseDialogBody>
            <p class="text-sm text-gray-500">
                <template v-if="messageText">
                    {{ t(messageText) }}
                </template>
                <template v-else>
                    {{ t('dialogs.confirmation.message') }}
                </template>
            </p>
        </BaseDialogBody>

        <template #actions>
            <BaseButton
                @click.once="confirm"
                :is-loading="isLoading"
                :is-disabled="isLoading"
                data-spec-class="dialog--confirm__confirm"
                class="mb-2 w-full sm:mb-0 sm:ml-1 sm:w-fit"
                :theme="theme"
            >
                <template v-if="confirmButtonText">
                    {{ t(confirmButtonText) }}
                </template>
                <template v-else>
                    {{ t('actions.confirm') }}
                </template>
            </BaseButton>
            <BaseButton
                @click="close"
                :theme="Theme.Secondary"
                data-spec-class="dialog--confirm__cancel"
                class="hidden sm:ml-1 sm:block"
            >
                {{ t('actions.cancel') }}
            </BaseButton>
        </template>
    </BaseDialog>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Size, Theme } from '../types';

const { t } = useI18n();
interface Props {
    isLoading: boolean;
    theme?: Theme;
    titleText?: string;
    messageText?: string;
    confirmButtonText?: string;
}

withDefaults(defineProps<Props>(), {
    theme: Theme.Primary,
    titleText: undefined,
    messageText: undefined,
    confirmButtonText: undefined,
});

const emits = defineEmits(['confirm']);

const isOpen = ref(false);

const open = () => {
    isOpen.value = true;
};

const close = () => {
    isOpen.value = false;
};

const confirm = async () => {
    try {
        await emits('confirm');
        close();
    } catch (error) {}
};
</script>
