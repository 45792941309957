<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div
        class="relative flex flex-col"
        data-spec-class="table"
    >
        <div class="overflow-x-auto">
            <div class="inline-block min-w-full pb-2 align-middle sm:pb-0">
                <slot name="wrapper">
                    <table class="min-w-full">
                        <slot />
                    </table>
                </slot>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
tr {
    td:first-child,
    th:first-child {
        padding-left: 0;
    }

    td:last-child,
    th:last-child {
        padding-right: 0;
    }
}
</style>
