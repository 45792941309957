<template>
    <form data-spec-class="form">
        <div class="overflow-hidden">
            <div class="py-5 sm:py-6">
                <slot />
            </div>
        </div>
        <div
            v-if="!!$slots.submit"
            class="pt-5 text-right"
        >
            <slot name="submit" />
        </div>
    </form>
</template>
