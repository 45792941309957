<template>
    <span
        class="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500 text-white"
        data-spec-class="avatar"
    >
        <span class="font-medium leading-none">
            <slot />
        </span>
    </span>
</template>
