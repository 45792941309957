import { isEven as isEvenNumber, isOdd as isOddNumber } from './numberUtils';

export function parseIndex(index: number | string | symbol): number {
    if (typeof index === 'string') {
        return parseInt(index, 10);
    }
    if (typeof index === 'symbol') {
        return parseInt(index.description ? index.description : '', 10);
    }
    return index;
}

export const isEven = (number: number | string | symbol): boolean =>
    isEvenNumber(parseIndex(number));

export const isOdd = (number: number | string | symbol): boolean => isOddNumber(parseIndex(number));
