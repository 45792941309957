<template>
    <slot
        name="error"
        :error="error"
        v-if="error"
    >
        <BaseAlertErrorUnconfirmedPassword v-if="error.message === errors.unconfirmedPassword" />
        <BaseAlertErrorServer v-else />
    </slot>
    <Suspense v-else>
        <template #default>
            <slot name="default" />
        </template>
        <template #fallback>
            <slot name="fallback" />
        </template>
    </Suspense>
</template>

<script setup lang="ts">
import { onErrorCaptured, ref } from 'vue';
import BaseAlertErrorServer from './BaseAlertErrorServer.vue';
import BaseAlertErrorUnconfirmedPassword from './BaseAlertErrorUnconfirmedPassword.vue';
import { errors } from '../modules/your/composables/core';

const error = ref(null);
onErrorCaptured((err: Error) => (error.value = err));
</script>
