/* eslint-disable import/prefer-default-export */

import { defineStore } from 'pinia';
import { AxiosResponse } from 'axios';
import axios from '../../../axios';
import {
    ObjectResponse,
    User,
    UpdateUserPasswordValidationError,
    confirmTwoFactorAuthenticationCodeValidationError,
    confirmPasswordCodeValidationError,
} from '../../../types';

const endpointUrl = '/api/v1/users';

export const useUserStore = defineStore('user', {
    state: () => ({
        currentUser: null as User | null,
        twoFactorAuthenticationQrCode: null as string | null,

        isFetchingUser: false,
        isUpdatingUserPassword: false,
        updatePasswordValidationError: null as UpdateUserPasswordValidationError | null,
        isSendingFeedback: false,
        sendFeedbackValidationErrors: false,

        isActivatingTwoFactorAuthentication: false,
        isDeactivatingTwoFactorAuthentication: false,
        isFetchingTwoFactorAuthenticationQrCode: false,
        isConfirmingTwoFactorAuthenticationCode: false,
        confirmTwoFactorAuthenticationCodeValidationError:
            null as confirmTwoFactorAuthenticationCodeValidationError | null,
        isFetchingTwoFactorAuthenticationRecoveryCodes: false,
        isConfirmingPassword: false,
        confirmPasswordValidationError: null as confirmPasswordCodeValidationError | null,
        isFetchingConfirmedPasswordStatus: false,
    }),
    actions: {
        async fetchCurrentUser() {
            try {
                this.isFetchingUser = true;
                const response: AxiosResponse<ObjectResponse> = await axios.get(
                    `${endpointUrl}/current`
                );
                this.currentUser = response.data.data as User;
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isFetchingUser = false;
            }
        },
        async updateCurrentUserPassword(fields) {
            try {
                this.isUpdatingUserPassword = true;
                this.updatePasswordValidationError = null;
                const response: AxiosResponse<ObjectResponse> = await axios.put(
                    `${endpointUrl}/current/update-password`,
                    fields
                );
                if (response.data.status === 422) {
                    this.updatePasswordValidationError = response.data.errors;
                } else {
                    this.currentUser = response.data.data as User;
                }
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isUpdatingUserPassword = false;
            }
        },
        async sendFeedback(message: string) {
            try {
                this.isSendingFeedback = true;
                const response: AxiosResponse<ObjectResponse> = await axios.post(
                    `${endpointUrl}/current/send-feedback`,
                    { message }
                );
                if (response.data.status === 422) {
                    this.sendFeedbackValidationErrors = response.data.errors;
                }
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isSendingFeedback = false;
            }
        },
        async activateTwoFactorAuthentication() {
            try {
                this.isActivatingTwoFactorAuthentication = true;
                await axios.post('/user/two-factor-authentication');
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isActivatingTwoFactorAuthentication = false;
            }
        },
        async deactivateTwoFactorAuthentication() {
            try {
                this.isDeactivatingTwoFactorAuthentication = true;
                await axios.delete('/user/two-factor-authentication');
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isDeactivatingTwoFactorAuthentication = false;
            }
        },
        async fetchTwoFactorAuthenticationQrCode() {
            try {
                this.isDeactivatingTwoFactorAuthentication = true;
                const response = await axios.get('/user/two-factor-qr-code ');
                this.twoFactorAuthenticationQrCode = response.data.svg;
                return Promise.resolve();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isDeactivatingTwoFactorAuthentication = false;
            }
        },
        async confirmTwoFactorAuthenticationCode(fields) {
            try {
                this.isConfirmingTwoFactorAuthenticationCode = true;
                const response = await axios.post(
                    '/user/confirmed-two-factor-authentication',
                    fields
                );
                this.twoFactorAuthenticationQrCode = response.data.svg;
                return Promise.resolve();
            } catch (error) {
                if (error.response.status === 422) {
                    this.confirmTwoFactorAuthenticationCodeValidationError =
                        error.response.data.errors;
                }
                return Promise.reject(error);
            } finally {
                this.isConfirmingTwoFactorAuthenticationCode = false;
            }
        },
        async fetchTwoFactorAuthenticationRecoveryCodes() {
            try {
                this.isDeactivatingTwoFactorAuthentication = true;
                const response = await axios.get('/user/two-factor-recovery-codes');
                return Promise.resolve(response.data);
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isDeactivatingTwoFactorAuthentication = false;
            }
        },
        async confirmPassword(password: string) {
            try {
                this.isConfirmingPassword = true;
                await axios.post('/user/confirm-password', { password });
                this.confirmPasswordValidationError = null;
                return Promise.resolve();
            } catch (error) {
                if (error.response.status === 422) {
                    this.confirmPasswordValidationError = error.response.data.errors;
                }
                return Promise.reject(error);
            } finally {
                this.isConfirmingPassword = false;
            }
        },
        async fetchConfirmedPasswordStatus() {
            try {
                this.isFetchingConfirmedPasswordStatus = true;
                const result = await axios.get('/user/confirmed-password-status');
                return Promise.resolve(result.data.confirmed);
            } catch (error) {
                return Promise.reject(error);
            } finally {
                this.isFetchingConfirmedPasswordStatus = false;
            }
        },
    },
});
