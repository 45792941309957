/* eslint-disable import/prefer-default-export */

import { reactive } from 'vue';
import { Toast, ToastType } from '../../../types';
import { timeInMilliseconds } from '../../../utils/timeUtils';

const DISMISS_TIME = 5000;

const toasts = reactive(new Set() as Set<Toast>);

export function useToast() {
    const createToast = (title: string, text = '', type: string) =>
        ({
            key: `toast-${timeInMilliseconds()}`,
            title,
            text,
            type,
            isVisible: false,
        }) as Toast;

    const deleteToast = (toast: Toast) => {
        const localToast = toast;
        localToast.isVisible = false;

        setTimeout(() => {
            toasts.delete(toast);
        }, 1000);
    };

    const addToast = (
        title: string,
        text: string,
        type: string,
        dismissTime: number | null = DISMISS_TIME
    ) => {
        const toast: Toast = createToast(title, text, type);
        toasts.add(toast);
        toast.isVisible = true;

        if (dismissTime) {
            setTimeout(() => {
                deleteToast(toast);
            }, dismissTime);
        }
    };

    const addSuccessToast = (title: string, text = '') => {
        addToast(title, text, ToastType.Success);
    };

    const addInfoToast = (title: string, text = '') => {
        addToast(title, text, ToastType.Info);
    };

    const addWarningToast = (title: string, text = '') => {
        addToast(title, text, ToastType.Warning, null);
    };

    const addErrorToast = (title: string, text = '') => {
        addToast(title, text, ToastType.Error, null);
    };

    return {
        toasts,
        createToast,
        deleteToast,
        addSuccessToast,
        addInfoToast,
        addWarningToast,
        addErrorToast,
    };
}
