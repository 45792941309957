<template>
    <BaseDialog
        :is-open="coreState.isPasswordConfirmationRequired"
        :size="Size.Small"
        @close="onCancel"
        data-spec-class="confirm_password-dialog"
    >
        <BaseDialogTitle data-spec-class="confirm_password-dialog__title">
            {{ t('dialogs.confirmPassword.title') }}
        </BaseDialogTitle>

        <BaseDialogBody>
            <TheConfirmPasswordForm />
        </BaseDialogBody>
    </BaseDialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { Size } from '../types';
import { useCore } from '../modules/your/composables/core';
import TheConfirmPasswordForm from './TheConfirmPasswordForm.vue';

const { coreState } = useCore();

const { t } = useI18n();

const onCancel = () => {
    coreState.isPasswordConfirmed = false;
    coreState.isPasswordConfirmationRequired = false;
};
</script>
