<template>
    <div data-spec-class="field">
        <div :class="labelWrapperClass">
            <div
                v-if="label || isOptional"
                class="flex justify-between"
            >
                <label
                    v-if="label"
                    :for="name"
                    :class="classObject"
                    data-spec-class="field__label"
                    v-html="label"
                    @click="emits('label-click')"
                >
                </label>
                <span
                    v-if="isOptional"
                    class="text-sm"
                    :class="{
                        'text-gray-500': customThemeBase === FormTheme.CustomerArea,
                        'text-gray-300': customThemeBase === FormTheme.Website,
                    }"
                    data-spec-class="field__optional"
                >
                    {{ t('forms.optional') }}
                </span>
            </div>
            <slot> </slot>
        </div>

        <div
            v-if="hint"
            class="mt-2 text-sm"
            :class="{
                'text-gray-500': customThemeBase === FormTheme.CustomerArea,
                'text-gray-300': customThemeBase === FormTheme.Website,
            }"
            data-spec-class="field__hint"
        >
            {{ hint }}
        </div>

        <div
            v-if="errorMessages.length"
            class="mt-2 text-sm text-red"
            data-spec-class="field__error"
        >
            <ul>
                <li
                    v-for="(errorMessage, i) in errorMessages"
                    :key="i"
                >
                    {{ errorMessage }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FormTheme } from '../types';

const emits = defineEmits(['update:modelValue', 'label-click']);

const { t } = useI18n();

interface Props {
    labelWrapperClass?: string;
    errorMessages?: string[];
    name: string;
    isOptional?: boolean;
    label?: string;
    hint?: string;
    customThemeBase?: FormTheme;
}

const props = withDefaults(defineProps<Props>(), {
    label: '',
    labelWrapperClass: '',
    hint: '',
    isOptional: false,
    vuelidateField: undefined,
    errorMessages: [],
    customThemeBase: undefined,
});

const themeBase = computed(() => {
    if (props.customThemeBase === FormTheme.Website) {
        return 'mb-2 block pl-5 text-left text-white';
    }

    return 'block text-sm font-medium text-gray-700 mb-1';
});

const hintThemeBase = computed(() => {
    if (props.customThemeBase === FormTheme.Website) {
        return 'mb-2 block pl-5 text-left text-white';
    }

    return 'block text-sm font-medium text-gray-700 mb-1';
});

const classObject = computed(() => ({
    [themeBase.value]: true,
}));
</script>
