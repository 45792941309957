<template>
    <div
        class="grid animate-pulse grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-8"
        data-spec-class="tabs-skeleton"
    >
        <div class="h-8 rounded bg-gray-200" />
        <div class="h-8 rounded bg-gray-200" />

        <div class="h-8 rounded bg-gray-300" />
    </div>
</template>
