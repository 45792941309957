<template>
    <div
        :class="classObject"
        data-spec-class="card-grid"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue';

interface Props {
    cols?: number;
}

const props = withDefaults(defineProps<Props>(), {
    cols: 1,
});

const classObject = computed(() => ({
    'grid grid-cols-1 gap-3': true,
    'sm:grid-cols-2': props.cols === 2,
}));
</script>
