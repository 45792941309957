<template>
    <div data-spec-class="spinner">
        <ArrowPathIcon
            :class="classObject"
            v-bind="$attrs"
        />
        <span class="sr-only">Loading</span>
    </div>
</template>

<script setup lang="ts">
import { computed, withDefaults, defineProps } from 'vue';
import { ArrowPathIcon } from '@heroicons/vue/20/solid';
import { SizeAll } from '../types';

interface Props {
    size?: SizeAll;
}

const props = withDefaults(defineProps<Props>(), {
    size: SizeAll.Normal,
});

const classObject = computed(() => ({
    'animate-spin': true,
    'w-5 h-5': props.size === SizeAll.Small || props.size === SizeAll.Tiny,
    'w-6 h-6': props.size === SizeAll.Normal,
    'w-7 h-7': props.size === SizeAll.Large || props.size === SizeAll.Huge,
}));
</script>
