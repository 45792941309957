<template>
    <Popover class="relative">
        <PopoverButton
            class="focus:outline-none"
            data-spec-class="feedback-button__trigger"
        >
            <div
                class="-mb-1.5 whitespace-nowrap rounded-t-md bg-blue-700 px-4 py-2 text-white transition duration-300 ease-out hover:-translate-y-1.5 ui-open:transform ui-open:opacity-0"
            >
                {{ t('actions.feedback') }}
            </div>
        </PopoverButton>

        <PopoverPanel
            v-slot="{ close }"
            class="absolute bottom-20 z-10 w-96 -translate-x-1/2 rotate-90"
            data-spec-class="feedback-button__panel"
        >
            <div class="rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
                <div class="absolute right-0 top-0 mr-3 hidden pt-3 sm:block">
                    <button
                        @click="onClose(close)"
                        type="button"
                        class="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                        data-spec-class="feedback-button__close"
                    >
                        <span class="sr-only">Close</span>
                        <XMarkIcon
                            class="h-6 w-6"
                            aria-hidden="true"
                        />
                    </button>
                </div>
                <div>
                    <div class="mb-3 text-center font-semibold text-gray-900">
                        {{ t('dialogs.feedback.title') }}
                    </div>
                    <UserFeedbackForm @submit="onClose(close)" />
                </div>
            </div>
        </PopoverPanel>
    </Popover>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { useI18n } from 'vue-i18n';
import UserFeedbackForm from '../modules/your/components/UserFeedbackForm.vue';

const { t } = useI18n();

const isOpen = ref(false);

const onClose = (close) => {
    isOpen.value = false;
    close();
};
</script>
