<template>
    <TransitionRoot
        :show="isOpen"
        enter="ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
        data-spec-class="overlay"
    >
        <div
            class="absolute z-10 h-full min-w-full bg-gray-300 bg-opacity-25 transition-opacity"
            v-bind="$attrs"
            data-spec-class="overlay-content"
        >
            <slot />
        </div>
    </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { TransitionRoot } from '@headlessui/vue';

export default defineComponent({
    inheritAttrs: false,
    components: { TransitionRoot },
    props: {
        isOpen: Boolean,
    },
});
</script>
