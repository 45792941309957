export default {
    components: {
        TheLocaleSwitcher: {
            locales: {
                de: 'deutsch',
                en: 'english',
            },
            toast: 'Language changed',
        },
    },
    pages: {
        DashboardPage: {
            title: 'Dashboard',
            statusTeaser: {
                title: 'Statuspage',
                content: 'Information on future and ongoing maintenance.',
                action: 'Open page',
            },
            helpTeaser: {
                title: 'Knowledge base',
                content: 'Further information and answers to frequently asked questions.',
                action: 'Open page',
            },
            contactTeaser: {
                title: 'Contacts',
                phone: 'Phone',
                mail: 'Mail',
                emergency: 'Emergency',
            },
            serviceTeaser: {
                title: 'Business hours',
                mondayToThursday: 'Mon. - Thu.',
                mondayToThursdayOpeningHours: '8:00 am - 5:00 pm',
                friday: 'Fri.',
                fridayOpeningHours: '8:00 am - 3:30 pm',
                info: 'All times are in German local time ({timeZone} [{utcDifference}]).',
            },
            changelogSection: {
                title: 'Recent @.lower:pages.ChangelogIndexPage.title',
            },
            ticketSection: {
                title: 'Open tickets',
            },
            helpfulLinksSection: {
                title: 'Helpful links',
            },
        },
        CustomerPage: {
            title: 'Customer data',
            breadcrumb: '@:pages.CustomerPage.title',
            contactSection: {
                inactiveSwitch: 'Deactivated @.lower:resources.contact.name',
            },
        },
        OrderIndexPage: {
            title: 'Orders',
            breadcrumb: '@:pages.OrderIndexPage.title',
        },
        OrderPage: {
            title: 'Order: %{number}',
            breadcrumb: '%{number}',
            type: {
                s3: {
                    managementInterfaceSection: {
                        title: 'Management interface',
                        groupName: 'Group Name: Customers',
                        incompleteSetUp:
                            'Access to the management interface is not yet fully set up. Please contact our support team.',
                    },
                    endpointSection: {
                        title: 'S3 endpoints (via HTTP or HTTPS)',
                        hint: 'The S3 access data can be found in the administration interface (the link under your user ID) under your user name ("Security Credentials"). You can then manage buckets and access keys via the interface.',
                    },
                },
            },
        },
        IpUsageIndexPage: {
            title: 'IPAM',
            breadcrumb: '@:pages.IpUsageIndexPage.title',
        },
        IpUsageNetworkPage: {
            title: 'IP network',
            breadcrumb: 'IP network',
        },
        OrderWanPage: {
            title: 'WAN-Traffic',
            breadcrumb: '@:pages.OrderWanPage.title',
        },
        OrderLanPage: {
            title: 'LAN-Traffic',
            breadcrumb: '@:pages.OrderLanPage.title',
        },
        OrderPowerPage: {
            title: 'Power consumption',
            breadcrumb: '@:pages.OrderPowerPage.title',
        },
        DeviceUsageIndexPage: {
            title: 'Server',
            breadcrumb: '@:pages.DeviceUsageIndexPage.title',
        },
        ColocationUsageIndexPage: {
            title: 'Colocation',
            breadcrumb: '@:pages.ColocationUsageIndexPage.title',
        },
        InvoiceIndexPage: {
            title: 'Invoices',
            breadcrumb: '@:pages.InvoiceIndexPage.title',
        },
        TicketIndexPage: {
            title: 'Tickets',
            breadcrumb: '@:pages.TicketIndexPage.title',
        },
        SettingsAccountPage: {
            title: 'Settings',
            breadcrumb: '@:pages.SettingsAccountPage.title',
            twoFactorAuthenticationDialog: {
                title: 'Activate two factor authentication',
                step1Label: 'Scan QR code',
                step1Description:
                    'Use an authentication app such as <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://authy.com/"> Authy </a>, <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=de&gl=US"> Google Authenticator </a> or a browser extension such as <a target="_blank" class="text-blue-700 hover:text-blue-600 hover:underline cursor-pointer" href="https://1password.com/downloads/browser-extension/">1Password</a> to scan.',
                confirmationCodeField: 'Confirmation code',
                step2Label: 'Enter confirmation code',
                step2Description: 'Enter the code from the authentication app for confirmation.',
                step3Label: 'Save recovery codes',
                step3Description:
                    'Save your recovery codes in a safe place so that you can regain access to your account if you lose your authentication app.',
            },
        },
        ChangelogIndexPage: {
            title: 'Changelogs',
            breadcrumb: '@:pages.ChangelogIndexPage.title',
        },
    },
    resources: {
        address: {
            name: 'Address | Addresses',
            attributes: {
                street: 'Street',
                number: 'House number',
                zip: 'Zip code',
                city: 'City',
                additional: 'Additional',
                country: 'Country',
            },
            emptyState: {
                title: 'Create @.lower:resources.address.name',
            },
        },
        country: {
            name: 'Land | Länder',
            attributes: {
                code: 'Ländercode',
                name: 'Name',
            },
        },
        bankAccount: {
            name: 'Bank account | Bank accounts',
            emptyState: {
                title: 'Create  @.lower:resources.bankAccount.name',
                body: 'Contact our support and create your @.lower:resources.bankAccount.name',
                mailSubject: '[23M.com] Create bank account ({customerNumber})',
                mailBody:
                    'Hello 23M-Support-Team %0D%0A%0D%0A I would like to create the following bank account...',
            },
        },
        contact: {
            name: 'Contact person | Contact persons',
            attributes: {
                is_authorized: 'Authorized',
                has_account: '@:resources.user.nameAlternative available',
            },
            actions: {
                add: 'Add @.lower:resources.contact.name',
            },
            emptyState: {
                title: 'Create @.lower:resources.contact.name',
            },
            messages: {
                canNotDeleteYourself: "You can't delete yourself.",
                deactivateConfirmation:
                    'Do you really want to deactivate this @.lower:resources.contact.name ?',
            },
        },
        customer: {
            name: 'Customer',
            attributes: {
                number: 'Customer number',
                name: 'Name',
                vat_id: 'VAT id',
                payment_type: 'Payment method',
                payment_due_days: 'Payment term',
                receipt_mail_address: 'Invoice mail',
            },
            enums: {
                payment_type: {
                    bank_transfer: 'Bank transfer',
                    direct_debit: 'Direct debit',
                    paypal: 'Paypal',
                },
            },
            receiptMailAddressEmptyState: {
                title: 'Create @.lower:resources.customer.attributes.receipt_mail_address',
            },
        },
        user: {
            name: 'User',
            nameAlternative: 'Login',
            attributes: {
                name: 'Name',
                password: 'Password',
                recovery_codes: 'Recovery codes',
            },
            actions: {
                createAccount: {
                    label: 'Create  @.lower:resources.user.nameAlternative',
                    hint: 'An login will be created based on the contact information and an invitation email will be sent.',
                },
                confirmPassword: 'Confirm password',
                activateTwoFactorAuthentication: {
                    successMessage: 'Two factor authentication activated',
                },
                deactivateTwoFactorAuthentication: {
                    successMessage: 'Two factor authentication deactivated',
                },
            },
        },
        order: {
            name: 'Order',
            attributes: {
                number: 'Order number',
                status: 'Status',
                type: 'Order type',
                completed_at: 'Completed at',
                approved_at: 'Approved at',
                planned_start_at: 'Planned to',
                start_at: 'Start date',
                end_at: 'Terminated at',
                setup_price: 'NRC',
                monthly_price: 'MRC',
                price: 'Price',
                cancelation_period: 'Cancellation period',
                extension_period: 'Extension',
                min_order_duration: 'Minimum duration',
            },
            enums: {
                status: {
                    draft: 'Draft',
                    completed: 'Offer',
                    approved: 'Approved',
                    due: 'Due',
                    active: 'Active',
                    canceled: 'Terminated',
                    terminated: 'Terminated',
                    rejected: 'Rejected',
                },
                decoratedStatus: {
                    startAt: 'Planned start on: {date}',
                    startsShortly: 'Starts shortly',
                    endAt: 'Terminated on {date}',
                },
                type: {
                    dedicated: 'Dedicated Server',
                    rent: 'Rent',
                    colocation: 'Colocation',
                    service: 'Service',
                    cloudserver: 'Cloud Server',
                    ip: 'Ip',
                    s3: 'S3 Object Storage',
                    domain_robot: 'Domain',
                    network: 'Network',
                },
            },
        },
        orderItem: {
            name: 'Order item | Order items',
            attributes: {
                category: 'Category',
                type: 'Type',
                description: 'Description',
            },
            enums: {
                category: {
                    device: 'Hardware',
                    ram: 'RAM',
                    storage: 'Storage',
                    network: 'Network',
                    service: 'Service',
                    ip: 'IP address | IP addresses',
                    s3: 'S3 Object Storage',
                    power: 'Power',
                    unknown: 'Unknown',
                    vpn: 'VPN',
                },
            },
            actions: {
                ipmiRequest: 'Show IPMI',
                showLanTrafficGraph: 'LAN traffic',
            },
            errors: {
                ipmiRequest: 'An error occurred during the IPMI query. Please try again later.',
            },
        },
        ipUsageAddress: {
            name: '',
            attributes: {
                ip: 'IP address | IP addresses',
                order_number: '@:resources.order.name',
                dns_name: 'rDNS name',
                customer_reference: 'Customer reference',
            },
        },
        ipUsageNetwork: {
            name: '',
            attributes: {
                ip: 'IP network | IP networks',
                order_number: '@:resources.order.name',
                network_ips: 'rDNS entries',
                has_dns_management: '@:resources.ipUsageAddress.attributes.has_dns_management',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
            },
        },
        networkIp: {
            name: '',
            attributes: {
                ip: 'IP address | IP addresses',
                mask: 'Subnet mask',
                dns_name: 'rDNS Name',
            },
        },
        deviceUsage: {
            name: '',
            attributes: {
                device_unit_name: 'Name',
                device_unit_old_name: 'Legacy name',
                device_name: 'Type',
                cpu: 'CPU',
                order_number: 'Order',
                customer_reference: '@:resources.ipUsageAddress.attributes.customer_reference',
                device_unit_component_unit_descriptions: 'Components',
                device_unit_netbox_item_rack_name: 'Rack',
                device_unit_netbox_item_rack_room: 'Room',
            },
            actions: {
                createCustomerReference:
                    'Create @.lower:resources.deviceUsage.attributes.customer_reference',
                editCustomerReference:
                    'Edit @.lower:resources.deviceUsage.attributes.customer_reference ',
            },
        },
        colocationUsage: {
            name: '',
            attributes: {
                id: 'ID',
                rack_name: 'Rack',
                rack_room: 'Room',
                order_number: '@:resources.order.name',
                order_colocation_item_customer_reference:
                    '@:resources.ipUsageAddress.attributes.customer_reference',
                order_colocation_item_colocation_name: 'Product',
                order_colocation_item_order_feed_item_current_power_consumption:
                    'Power consumption',
            },
            actions: {
                createCustomerReference:
                    'Create @.lower:resources.colocationUsage.attributes.order_colocation_item_customer_reference',
                editCustomerReference:
                    'Edit @.lower:resources.colocationUsage.attributes.order_colocation_item_customer_reference',
            },
        },
        orderTrafficItem: {
            name: 'Traffic',
            attributes: {
                pricePerUnit: 'Per additional {unit} {price}',
            },
            actions: {
                showWanTrafficGraph: 'WAN traffic',
            },
        },
        orderS3Item: {
            name: 'S3 Object Storage',
            attributes: {
                username: 'User ID',
                pricing: '{pricePerUnit} per GB / per month',
            },
            actions: {
                managementInterface: 'Open',
                editPassword: 'Edit password',
            },
        },
        orderIpItem: {
            name: 'IP addresses',
        },
        orderDomainItem: {
            name: 'Domain Services',
            attributes: {
                domain_username: 'Username',
            },
            actions: {
                openDomainRobot: 'Open domain robot',
            },
        },
        orderFeedItem: {
            attributes: {
                fuse_protection: 'Protection per feed {fuseProtection} A',
            },
        },
        orderPowerItem: {
            pricing: '{pricePerUnit} per kWh',
        },
        orderColocationItem: {
            attributes: {
                rack_name: 'Rack',
            },
        },
        orderCloudserverItem: {
            name: 'Cloud Server',
        },
        orderLockerItem: {
            name: 'Locker',
            attributes: {
                locker_room: 'Room',
                locker_name: 'Cabinet',
                compartment: 'Compartment',
            },
        },
        orderVpnItem: {
            name: '{ count } { type } | { count } { type }s',
        },
        orderCrossConnectItem: {
            name: 'Cross Connect',
            attributes: {
                type: 'Type',
                usage_interface_name: 'Name',
                netbox_cross_connect_name: 'Identifier',
                netbox_rack_name: 'Rack',
                netbox_rack_room: 'Room',
                connected_item_order_number: 'Connected Order',
            },
        },
        invoice: {
            name: 'Invoice | Invoices',
            attributes: {
                number: 'Invoice number',
                status: 'Status',
                date: 'Invoice date',
                due_on: 'Due date',
                total_net: 'Net',
                total_gross: 'Gross',
            },
            enums: {
                status: {
                    open: 'Open',
                    paid: 'Paid',
                },
            },
        },
        ticket: {
            name: 'Ticket | Tickets',
            attributes: {
                subject: 'Subject',
                id: 'ID',
                created_at: 'Created',
                updated_at: 'Last activity',
                status: 'Status',
                topic: 'Topic',
                description: 'Description',
            },
            enums: {
                status: {
                    new: 'Open',
                    open: 'Open',
                    pending: 'Awaiting your reply',
                    closed: 'Solved',
                    solved: 'Solved',
                },
                topic: {
                    sales: 'Product or quotation enquiry',
                    finance: 'Question about a settlement',
                    technic: 'Technical support (during business hours)',
                    emergency: 'Technical emergency (outside business hours)',
                    miscellaneous: 'Miscellaneous',
                },
            },
            actions: {
                selectStatus: 'Any',
            },
        },
    },
    messages: {
        info: {
            newOffer: 'You have a new offer',
            empty: 'No data found',
        },
        success: {
            saved: 'Saved successfully',
        },
    },
    forms: {
        optional: 'Optional',
        addressDach: {
            street: {
                label: 'Street',
                placeholder: '',
            },
            number: {
                label: 'House number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            zip: {
                label: 'Zip code',
                placeholder: '',
            },
            city: {
                label: 'City',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
        addressNonDach: {
            street: {
                label: 'Street & house number',
                placeholder: '',
            },
            country: {
                label: 'Country',
            },
            city: {
                label: 'Zip code & city',
                placeholder: '',
            },
            additional: {
                label: 'Additional',
                placeholder: '',
            },
        },
        contact: {
            title: {
                label: 'Title',
                placeholder: '',
            },
            first_name: {
                label: 'First name',
                placeholder: '',
            },
            last_name: {
                label: 'Last name',
                placeholder: '',
            },
            gender: {
                label: 'Salutation',
                options: {
                    m: 'Mr.',
                    f: 'Mrs.',
                    d: 'Neutral',
                },
            },
            email: {
                label: 'Mail',
                placeholder: '',
            },
            phone: {
                label: 'Phone',
                placeholder: '',
            },
            mobile: {
                label: 'Mobile',
                placeholder: '',
            },
            is_authorized: {
                label: 'Authorized',
            },
            is_creating_account: {
                label: 'Create a @.lower:resources.user.nameAlternative ?',
            },
        },
        updateUserPassword: {
            title: 'Change password',
            description: 'Update your password associated with your account.',
            password_current: {
                label: 'Current password',
                placeholder: '',
            },
            password: {
                label: 'New password',
                placeholder: '',
            },
            password_confirmation: {
                label: 'Confirm password',
                placeholder: '',
            },
        },
        twoFactorAuthentication: {
            title: 'Two factor authentication',
            description: 'Increase the security of your account with two factor authentication.',
            has_two_factor_authentication: {
                label: 'Authenticator app',
            },
            recovery_codes: {
                label: '@:resources.user.attributes.recovery_codes',
            },
        },
        sendFeedback: {
            message: {
                label: 'Your message',
                placeholder: 'What would you like us to know?',
            },
            submit: 'Send feedback',
        },
        ticket: {
            topic: {
                label: '@:resources.ticket.attributes.topic',
            },
            subject: {
                label: '@:resources.ticket.attributes.subject',
            },
            description: {
                label: '@:resources.ticket.attributes.description',
            },
            uploads: {
                label: 'Attachments',
            },
        },
    },
};
