import * as validators from '@vuelidate/validators';
import * as customValidators from '../../../composables/customValidator';
import i18n from './index';

const { createI18nMessage } = validators;

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) });

export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const requiredIf = withI18nMessage(validators.requiredIf, { withArguments: true });
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const between = withI18nMessage(validators.between, { withArguments: true });
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });

export const ipV4 = withI18nMessage(customValidators.ipV4);
export const fileSize50 = withI18nMessage(customValidators.fileSize50, { withArguments: true });
export const mustBeConfirmed = withI18nMessage(customValidators.mustBeConfirmed, {
    withArguments: true,
});
