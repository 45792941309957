<template>
    <component
        :is="tag"
        :class="[
            'focus:ring-indigo-500 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center',
            props.hasAction
                ? 'pointer hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2'
                : '',
        ]"
        data-spec-class="empty-state"
    >
        <div class="mx-auto h-12 w-12 text-gray-400">
            <slot name="icon" />
        </div>

        <slot />
    </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';

interface Props {
    hasAction?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    hasAction: true,
});

const tag = computed(() => {
    if (props.hasAction) {
        return 'a';
    }

    return 'div';
});
</script>
