<template>
    <TransitionRoot
        as="template"
        :show="isOpen"
    >
        <Dialog
            as="div"
            class="absolute z-10"
            @close="onClose"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div
                    class="flex min-h-full items-end items-center justify-center p-4 text-center sm:p-0"
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
                            :class="classObjectPanel"
                        >
                            <div
                                v-if="isClosable"
                                class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block"
                            >
                                <button
                                    type="button"
                                    class="focus:ring-indigo-500 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                                    @click="onClose"
                                    data-spec-class="dialog__close"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon
                                        class="h-6 w-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <div
                                v-if="!!$slots.icon"
                                class="mx-auto mb-6 flex h-20 w-20 items-center justify-center"
                            >
                                <slot name="icon" />
                            </div>
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <slot />
                            </div>

                            <div
                                v-if="isClosable || !!$slots.actions"
                                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                            >
                                <slot name="actions" />
                                <BaseButton
                                    v-if="isClosable && hasMobileCancelButton"
                                    :theme="Theme.Secondary"
                                    @click="onClose"
                                    class="w-full sm:hidden sm:w-fit"
                                    data-spec-class="dialog__cancel"
                                >
                                    {{ t('actions.cancel') }}
                                </BaseButton>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits, computed } from 'vue';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useI18n } from 'vue-i18n';

import { Size, Theme } from '../types';
import BaseButton from './BaseButton.vue';

const { t } = useI18n();

interface Props {
    isOpen?: boolean;
    size?: Size;
    isClosable?: boolean;
    hasMobileCancelButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    isOpen: false,
    size: Size.Normal,
    isClosable: true,
    hasMobileCancelButton: true,
});

const emits = defineEmits(['close']);

const classObjectPanel = computed(() => ({
    '': props.size === Size.None,
    'w-full sm:max-w-2xl': props.size === Size.Small,
    'w-full sm:max-w-4xl': props.size === Size.Normal,
    'w-full sm:max-w-8xl': props.size === Size.Large,
}));

const onClose = () => {
    if (props.isClosable) {
        emits('close');
    }
};
</script>
