<template>
    <div
        class="py-5 sm:py-6"
        data-spec-class="form-skeleton"
    >
        <div class="grid animate-pulse grid-cols-6 gap-6">
            <div class="col-span-6">
                <div class="mb-2 h-6 w-1/6 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <div class="mb-2 h-6 w-1/6 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6 sm:col-span-2">
                <div class="mb-2 h-6 w-1/2 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6">
                <div class="mb-2 h-6 w-1/4 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6 sm:col-span-2 sm:col-start-1">
                <div class="mb-2 h-6 w-1/2 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <div class="mb-2 h-6 w-1/3 rounded bg-gray-300" />
                <div class="h-9 rounded bg-gray-200" />
            </div>

            <div class="col-span-6 mt-2 sm:col-span-1 sm:col-start-6">
                <div class="mb-2 h-8 w-full rounded bg-gray-300" />
            </div>
        </div>
    </div>
</template>
