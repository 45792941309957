<template>
    <div>
        <ul
            class="flex"
            data-spec-class="display-type_switch"
        >
            <li
                @click="onSwitch(DisplayType.Table)"
                data-spec-class="display-type_switch__table"
                :class="[
                    'mx-1 border-b-2',
                    displayType === DisplayType.Table ? activeClass : inactiveClass,
                ]"
            >
                <TableCellsIcon class="h-8 w-8" />
            </li>
            <li
                @click="onSwitch(DisplayType.Grid)"
                data-spec-class="display-type_switch__grid"
                :class="[
                    'mx-1 border-b-2',
                    displayType === DisplayType.Grid ? activeClass : inactiveClass,
                ]"
            >
                <Squares2X2Icon class="h-8 w-8" />
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { Squares2X2Icon, TableCellsIcon } from '@heroicons/vue/24/outline';
import { ref, defineEmits } from 'vue';
import { DisplayType } from '../types';

interface Props {
    displayType?: DisplayType;
}

withDefaults(defineProps<Props>(), {
    displayType: DisplayType.Table,
});

const emits = defineEmits(['switch']);

const activeClass = 'border-blue-700';
const inactiveClass = 'text-gray-300 hover:text-blue-600 border-blue-100 cursor-pointer';

const onSwitch = (key: string) => {
    emits('switch', key);
};
</script>
