<template>
    <BaseField
        :label="label"
        :hint="hint"
        :name="name"
        data-spec-class="field-switch"
    >
        <button
            :class="[
                modelValue ? 'bg-blue-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
            ]"
            :aria-checked="modelValue"
            @click.prevent="onClick"
        >
            <span class="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                :class="[
                    modelValue ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                ]"
            />
        </button>
    </BaseField>
</template>

<script setup lang="ts">
import { withDefaults, defineProps, defineEmits } from 'vue';

const emits = defineEmits(['update:modelValue', 'click']);

interface Props {
    modelValue?: boolean;
    name: string;
    label?: string;
    hint?: string;
    isRemoteControlled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    modelValue: false,
    label: '',
    hint: '',
    isRemoteControlled: false,
});

const onClick = () => {
    if (!props.isRemoteControlled) {
        emits('update:modelValue', !props.modelValue);
    }
    emits('click');
};
</script>
