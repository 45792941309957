<template>
    <a
        href="#"
        v-bind="$attrs"
        @click.prevent="onLogout"
        data-spec-class="logout-link"
    >
        <slot>{{ t('actions.logout') }}</slot>
    </a>

    <Teleport to="body">
        <form
            method="POST"
            :action="`${baseUrl}/logout${redirectParams}`"
            role="none"
            ref="logoutFormRef"
        >
            <BaseFieldCsrf />
        </form>
    </Teleport>
</template>

<script setup lang="ts">
import { ref, defineProps } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n({ useScope: 'global' });

const logoutFormRef = ref(null);
const baseUrl = window.location.origin;

defineProps({
    redirectParams: { default: '' },
});

const onLogout = () => {
    logoutFormRef.value.submit();
};
</script>
