/* eslint-disable import/prefer-default-export */

import { helpers } from '@vuelidate/validators';

export const ipV4 = helpers.regex(
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
);

export const fileSize50 = (max = 50) => {
    const maxInBytes = max * 1000 * 1000;

    return (value: File) => {
        if (!value) {
            return { $valid: true };
        }
        let file = value;
        let isValid = file.size < maxInBytes;
        return { $valid: isValid, $params: { max: max } };
    };
};

export const mustBeConfirmed = () => {
    return (value) => {
        return { $valid: value === true };
    };
};
