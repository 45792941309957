<template>
    <component
        :is="tag"
        type="button"
        :to="to"
        :href="url"
        :class="classObject"
        :disabled="isLoading || isDisabled"
        data-spec-class="button"
    >
        <slot name="prepend" />
        <span class="flex items-center px-4">
            <span />
            <BaseSpinner
                v-if="isLoading"
                :size="size"
                class="mr-0.5"
            />
            <slot />
        </span>
    </component>
</template>

<script lang="ts" setup>
import { computed, withDefaults, defineProps, defineEmits, defineExpose } from 'vue';
import { RouteLocationNormalized, RouterLink, useRouter } from 'vue-router';
import { SizeAll, Theme } from '../types';

const router = useRouter();

const emits = defineEmits([]);

interface Props {
    theme?: Theme;
    href?: string;
    to?: RouteLocationNormalized;
    isDisabled?: boolean;
    size?: SizeAll;
    isLoading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Normal,
    href: undefined,
    to: undefined,
    isDisabled: false,
    size: SizeAll.Normal,
    isLoading: false,
});

const tag = computed(() => {
    if (props.to) {
        return RouterLink;
    }
    if (props.href) {
        return 'a';
    }

    return 'button';
});

const url = computed(() => {
    if (props.to) {
        return router.resolve(props.to).fullPath;
    }
    if (props.href) {
        return props.href;
    }

    return '#';
});

const classObject = computed(() => ({
    'relative inline-flex justify-center items-center font-medium transition rounded-full cursor-pointer':
        true,
    'py-1 px-0 text-xs': props.size === SizeAll.Tiny,
    'py-2 px-2.5 text-xs': props.size === SizeAll.Small,
    'py-2 px-2.5 text-sm': props.size === SizeAll.Normal,
    'bg-none hover:bg-blue-300 border border-blue-700 text-blue-700': props.theme === Theme.Normal,
    'bg-none hover:bg-gray-100 border border-gray-300 text-gray-300':
        props.theme === Theme.Secondary,
    'bg-blue-700 hover:bg-blue-600 text-white': props.theme === Theme.Primary,
    'bg-purple-700 hover:bg-purple-600 text-white': props.theme === Theme.Cta,
    'bg-none hover:bg-red-lighter border border-red text-red': props.theme === Theme.Danger,
    'disabled:opacity-50 disabled:cursor-not-allowed': props.isDisabled === true,
}));

function remoteTrigger() {
    emits('click');
}
defineExpose({ remoteTrigger });
</script>
