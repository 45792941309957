<template>
    <div
        class="relative"
        data-spec-class="tooltip"
        ref="tooltipRef"
        v-click-outside="() => onCancel()"
    >
        <div
            :class="triggerClassObject"
            @click="onUpdate"
            data-spec-class="tooltip__trigger"
        >
            <slot name="trigger" />
        </div>
        <span
            v-if="isActive"
            :class="messageClassObject"
            data-spec-class="tooltip__message"
        >
            <slot />
        </span>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { Theme, TooltipPosition } from '../types';

interface Props {
    theme?: Theme;
}

const props = withDefaults(defineProps<Props>(), {
    theme: Theme.Dark,
});

const emits = defineEmits(['update']);

const triggerClassObject = computed(() => ({
    'cursor-pointer': true,
}));

const messageClassObject = computed(() => ({
    'triangle absolute w-56 rounded-md border p-2 text-sm': true,
    'triangle-right right-0 top-0 mr-9': position.value === TooltipPosition.Left,
    'triangle-left left-full ml-2 top-0': position.value === TooltipPosition.Right,
    'dark border-gray-400 bg-gray-600 text-gray-100': props.theme === Theme.Dark,
}));

const isActive = ref(false);
const tooltipRef = ref(null);
const messageWidth = 224;
const position = ref(TooltipPosition.Left);

function onUpdate() {
    isActive.value = !isActive.value;
    emits('update', isActive.value);
}

function onCancel() {
    isActive.value = false;
    emits('update', isActive.value);
}

function setPosition() {
    position.value =
        tooltipRef.value.getBoundingClientRect().left >= messageWidth
            ? TooltipPosition.Left
            : TooltipPosition.Right;
}

watch(
    () => isActive.value,
    () => {
        setPosition();
    }
);

onMounted(() => {
    window.addEventListener('resize', setPosition);
});

onUnmounted(() => {
    window.removeEventListener('resize', setPosition);
});
</script>

<style scoped>
span.triangle::after {
    content: '';
    @apply absolute mt-1 border-[8px];
}

span.triangle-right::after {
    content: '';
    @apply right-0 top-0 -mr-[16px];
}
span.triangle-left::after {
    content: '';
    @apply left-0 top-0 -ml-[16px];
}

span.triangle.dark::after {
    @apply border-gray-400 border-b-transparent border-t-transparent;
}

span.triangle-left.dark::after {
    @apply border-l-transparent;
}

span.triangle-right.dark::after {
    @apply border-r-transparent;
}
</style>
