<template>
    <div
        class="py-5 sm:py-6"
        data-spec-class="graph-skeleton"
    >
        <div class="grid animate-pulse grid-cols-24 gap-4">
            <div class="col-span-1 mt-auto">
                <div class="h-24 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-32 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-40 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-300" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-64 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-40 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-64 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-80 rounded bg-gray-300" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-64 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-32 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-40 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-64 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-80 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-96 rounded bg-gray-300" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-80 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-64 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-48 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-32 rounded bg-gray-200" />
            </div>
            <div class="col-span-1 mt-auto">
                <div class="h-24 rounded bg-gray-200" />
            </div>
        </div>
    </div>
</template>
