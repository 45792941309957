<template>
    <th
        scope="col"
        :class="classObject"
        @click="onSortingClick"
        data-spec-class="table-cell-header"
        :data-spec-id="attributeName ? `table-cell-header--${attributeName}` : UNSORTED"
        :data-spec-value="currentSortDirection"
    >
        <slot />

        <ChevronDownIcon
            :class="iconClassObject"
            aria-hidden="true"
            :style="iconStyleObject"
        />
    </th>
</template>

<script setup lang="ts">
import { withDefaults, defineEmits, defineProps, computed, ref, onMounted, watch } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import { TableCellType, SortDirection } from '../types';

interface Props {
    type?: TableCellType;
    isSortable?: boolean;
    attributeName?: string;
    sorting?: string;
}

const props = withDefaults(defineProps<Props>(), {
    type: TableCellType.Normal,
    isSortable: false,
    attributeName: undefined,
    sorting: undefined,
});

const UNSORTED = 'unsorted';

const emits = defineEmits(['update:page-sorting']);

function initialSortDirection() {
    if (props.sorting === `${props.attributeName}:asc`) {
        return SortDirection.Asc;
    }

    if (props.sorting === `${props.attributeName}:desc`) {
        return SortDirection.Desc;
    }

    return UNSORTED;
}

const currentSortDirection = ref(initialSortDirection());

const iconRotating = ref(currentSortDirection.value === SortDirection.Desc ? 180 : 0);

const isUnsorted = computed(() => currentSortDirection.value === UNSORTED);
const isSorted = computed(() => !isUnsorted.value);
const isSortableCondition = computed(() => {
    if (props.isSortable && props.attributeName === undefined) {
        console.warn(
            '[TableCellHeader]: No attributeName provided. Fallback to unsortable behavior.'
        );
    }
    return props.isSortable && props.attributeName;
});

const classObject = computed(() => ({
    'py-1.5 px-6 pr-1 font-bold whitespace-nowrap': true,
    'cursor-pointer group hover:text-gray-500': isSortableCondition.value,
    'text-left font-bold text-gray-400': props.type === TableCellType.Normal,
    'text-gray-500': props.type === TableCellType.Normal && isSorted.value,
    relative: props.type === TableCellType.Actions,
}));

const iconClassObject = computed(() => ({
    'h-5 w-5 inline ml-2': true,
    'group-hover:text-gray-300 text-transparent': isUnsorted.value,
}));

const iconStyleObject = computed(() => ({
    transform: `rotate(${iconRotating.value}deg)`,
    transition: 'transform 150ms ease',
}));

function onSortingClick() {
    if (!isSortableCondition.value) {
        return;
    }

    if (currentSortDirection.value === UNSORTED) {
        currentSortDirection.value = SortDirection.Asc;
        iconRotating.value = 0;
    } else if (currentSortDirection.value === SortDirection.Asc) {
        currentSortDirection.value = SortDirection.Desc;
        iconRotating.value = 180;
    } else if (currentSortDirection.value === SortDirection.Desc) {
        currentSortDirection.value = UNSORTED;
        iconRotating.value = 0;
    }
    const sortDirection = currentSortDirection.value === UNSORTED ? '' : currentSortDirection.value;
    emits('update:page-sorting', { attributeName: props.attributeName, sortDirection });
}

watch(
    () => props.sorting,
    (newSorting) => {
        const newAttributeName = newSorting.split(':')[0];
        if (newAttributeName && newAttributeName !== props.attributeName) {
            currentSortDirection.value = UNSORTED;
        }
        if (
            newAttributeName &&
            newAttributeName === props.attributeName &&
            currentSortDirection.value === ''
        ) {
            setInitialSortDirection();
        }
    }
);
</script>
