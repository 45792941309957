<template>
    <ul class="animate-pulse">
        <li>
            <div class="mb-2 h-5 rounded bg-gray-300" />
        </li>
        <li>
            <div class="mb-2 h-5 rounded bg-gray-200" />
        </li>
        <li>
            <div class="mb-2 h-5 rounded bg-gray-300" />
        </li>
        <li>
            <div class="mb-2 h-5 rounded bg-gray-200" />
        </li>
        <li>
            <div class="mb-2 h-5 rounded bg-gray-300" />
        </li>
    </ul>
</template>
