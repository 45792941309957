<template>
    <div class="mt-3 flex flex-col items-center py-3 lg:mx-12">
        <div class="whitespace-nowrap text-xl text-blue-700 lg:ml-12 lg:mr-6">
            <slot name="title"></slot>
        </div>

        <div class="mx-6 text-gray-300 lg:ml-6 lg:mr-12 lg:w-2/3">
            <slot></slot>
        </div>
    </div>
</template>
