<template>
    <RouterLink
        v-if="to"
        :to="to"
        :class="classObject"
        data-spec-class="link"
        @click="isDisabled ? '' : emits('click')"
    >
        <slot />
        <component
            class="ml-1 h-5 w-5"
            :is="icon"
            v-if="icon"
            data-spec-class="link__icon"
        />
    </RouterLink>
    <a
        v-else
        :href="href ? href : 'javascript:;'"
        :class="classObject"
        data-spec-class="link"
        @click="isDisabled || href ? '' : emits('click')"
    >
        <slot />
        <component
            class="ml-1 h-5 w-5"
            :is="icon"
            v-if="icon"
            data-spec-class="link__icon"
        />
    </a>
</template>

<script setup lang="ts">
import { defineProps, withDefaults, defineEmits, computed } from 'vue';

import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';
import { Theme, IconKey } from '../types';
import { RouteLocationNormalized } from 'vue-router';

const ICON_REPOSITORY = {
    [IconKey.Extern]: ArrowTopRightOnSquareIcon,
};

interface Props {
    isDisabled?: boolean;
    theme?: Theme;
    to?: RouteLocationNormalized;
    href?: string;
    iconKey?: IconKey;
    customThemeBase?: string;
    customThemeNormal?: string;
}

const props = withDefaults(defineProps<Props>(), {
    isDisabled: false,
    theme: Theme.Normal,
    to: undefined,
    href: undefined,
    iconKey: undefined,
    customThemeBase: undefined,
    customThemeNormal: undefined,
});

const emits = defineEmits(['click']);

const themeBase = computed(() => {
    const defaultThemeBase = 'flex items-center hover:underline';
    return typeof props.customThemeBase === 'string' ? props.customThemeBase : defaultThemeBase;
});

const themeNormal = computed(() => {
    const defaultThemeNormal = 'text-blue-700 hover:text-blue-600 cursor-pointer';
    return typeof props.customThemeNormal === 'string'
        ? props.customThemeNormal
        : defaultThemeNormal;
});

const classObject = computed(() => ({
    [themeBase.value]: true,
    [themeNormal.value]: props.theme === Theme.Normal,
    'text-gray-300 cursor-not-allowed': props.isDisabled,
}));

const icon = computed(() => ICON_REPOSITORY[props.iconKey]);
</script>
