<template>
    <BaseButton
        v-if="!isEditing"
        :size="SizeAll.Tiny"
        @click="emits('edit')"
        data-spec-class="button-group-edit__edit"
    >
        {{ t('actions.edit') }}
    </BaseButton>

    <BaseButton
        v-if="isEditing"
        :size="SizeAll.Tiny"
        :is-loading="isUpdating"
        @click.prevent="emits('update')"
        class="mr-1"
        data-spec-class="button-group-edit__save"
    >
        {{ t('actions.save') }}
    </BaseButton>

    <BaseButton
        v-if="isEditing"
        :size="SizeAll.Tiny"
        @click="emits('cancel')"
        :theme="Theme.Secondary"
        data-spec-class="button-group-edit__cancel"
    >
        {{ t('actions.cancel') }}
    </BaseButton>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';
import { SizeAll, Theme } from '../types';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emits = defineEmits(['edit', 'update', 'cancel']);

defineProps<{
    isEditing: boolean;
    isUpdating: boolean;
}>();
</script>
